import React from 'react';
import { Select, Button, Checkbox } from '@mantine/core';
import { faDisplay } from '@fortawesome/free-solid-svg-icons';

function RandomPage() {
  return (
    <div className="main-container">

    <div style={{display: "flex", flexDirection:"column", gap: "20px"}} className="p-6">
      {/* Row container for dropdowns and scrollable list */}
      <div style={{display: "flex", gap: "20px"}} className="flex flex-row gap-6">
        {/* Flex column for dropdowns */}
        <div style={{width: "100%"}} className="flex flex-col gap-4">
          <Select
            label="Cloud Provider"
            placeholder="Pick value"
            data={['AWS', 'Azure', 'GCP', 'DigitalOcean']}
          />
          <Select
            label="Tag Key"
            placeholder="Pick value"
            data={['Environment', 'Project', 'Pipeline']}
          />
          <Select
            label="Tag Value"
            placeholder="Pick value"
            data={['Production', 'Development', 'Testing']}
          />
          <Select
            label="Region"
            placeholder="Pick value"
            data={['North Virginia', 'Oregon', 'Singapore']}
          />
          <Select
            label="Lock Status"
            placeholder="Pick value"
            data={['Lock', 'Unlock']}
          />
          <Button variant="filled">Save</Button>
        </div>

        {/* Scrollable container */}
        <div style={{width: "100%"}} className="overflow-y-scroll h-48 border border-gray-300 rounded-lg p-4 w-64">
          <div className="mb-2">Environment: Production</div>
          <div className="mb-2">Environment: Development</div>
          <div className="mb-2">Environment: Testing</div>
          <div className="mb-2">Environment: Staging</div>
        </div>
      </div>

      {/* Row container for locked and unlocked tags */}
      <div style={{display: "flex", gap: "20px"}} className="flex flex-row gap-6 mt-6">
        {/* Locked Tags */}
        <div style={{width: "100%"}} className="flex-1 border border-gray-300 rounded-lg p-4">
          <h3 className="mb-4 text-lg font-semibold">Locked Tags</h3>
          <Checkbox label="Environment: Production" />
          <Checkbox label="Project: JARVIS" />
          <Checkbox label="Pipeline: Alpha" defaultChecked />
          <Button variant="filled" className="mt-4">
            Unlock
          </Button>
        </div>

        {/* Unlocked Tags */}
        <div style={{width: "100%"}} className="flex-1 border border-gray-300 rounded-lg p-4">
          <h3 className="mb-4 text-lg font-semibold">Unlocked Tags</h3>
          <Checkbox label="Environment: Development" />
          <Checkbox label="Project: BETA" />
          <Checkbox label="Pipeline: Testing" />
          <Button variant="filled" className="mt-4">
            Lock
          </Button>
        </div>
      </div>
    </div>
    </div>
  );
}

export default RandomPage;
