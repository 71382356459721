import React from "react";
import { Bar, Pie, Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
import "./Chart.css";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  // Bar chart data and configuration
  const barChartData = {
    labels: ["2016", "2017", "2018", "2019", "2020"],
    datasets: [
      {
        label: "Users Gained",
        data: [80000, 45677, 78888, 90000, 4300],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Users Lost",
        data: [823, 345, 555, 4555, 234],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "User Statistics (2016-2020)",
      },
      legend: {
        position: "top",
      },
    },
  };

  // Pie chart data and configuration
  const pieChartData = {
    labels: ["Red", "Orange", "Yellow", "Green", "Blue"],
    datasets: [
      {
        label: "Dataset 1",
        data: [12, 19, 3, 5, 2],
        backgroundColor: [
          "#FF6384",
          "#FF9F40",
          "#FFCD56",
          "#4BC0C0",
          "#36A2EB",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Pie Chart",
      },
    },
  };

  // Line chart data and configuration
  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        data: [65, 59, 55, 48, 56, 57, 40],
        borderColor: "rgb(75, 192, 192)",
        segment: {
          borderColor: ctx => ctx.p0.parsed.y > ctx.p1.parsed.y ? 'rgb(255, 99, 132)' : undefined,
        },
        tension: 0.1
      },
      {
        label: "Dataset 2",
        data: [45, 52, 48, 58, 52, 48, 42],
        borderColor: "rgb(53, 162, 235)",
        segment: {
          borderColor: ctx => ctx.p0.parsed.y > ctx.p1.parsed.y ? 'rgb(255, 205, 86)' : undefined,
        },
        tension: 0.1
      }
    ],
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Line Segment Styling",
      },
    },
    scales: {
      y: {
        min: 30,
        max: 70,
      }
    }
  };

  // Stacked Bar chart data and configuration
  const stackedBarChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        data: [40, 35, 75, 15, -50, -30, 60],
        backgroundColor: "rgb(255, 99, 132)",
        stack: "Stack 0",
      },
      {
        label: "Dataset 2",
        data: [10, 15, 35, 95, 95, 10, 70],
        backgroundColor: "rgb(54, 162, 235)",
        stack: "Stack 0",
      },
      {
        label: "Dataset 3",
        data: [90, 25, -15, 65, 10, -80, 75],
        backgroundColor: "rgb(75, 192, 192)",
        stack: "Stack 0",
      },
    ],
  };

  const stackedBarChartOptions = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: -150,
        max: 250,
        ticks: {
          stepSize: 50,
        },
      },
    },
  };

  // Doughnut chart data and configuration
  const doughnutChartData = {
    labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
    datasets: [
      {
        data: [300, 150, 400, 200, 350],
        backgroundColor: [
          'rgb(255, 99, 132)',   // Red
          'rgb(255, 159, 64)',   // Orange
          'rgb(255, 205, 86)',   // Yellow
          'rgb(75, 192, 192)',   // Green
          'rgb(54, 162, 235)',   // Blue
        ],
        hoverOffset: 4,
        borderWidth: 1,
        cutout: '70%'  // This makes it a doughnut chart
      }
    ]
  };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          padding: 20
        }
      },
      title: {
        display: true,
        text: 'Chart.js Doughnut Chart',
        padding: {
          top: 10,
          bottom: 30
        }
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  return (
    <div className="chart-wrapper" style={{ marginTop: '110px' }}>
      <div className="chart-container" >
        <h2>Doughnut Chart</h2>
        <div style={{ height: '410px' }}>
          <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
        </div>
      </div>
      <div className="chart-container">
        <h2>Stacked Bar Chart</h2>
        <Bar data={stackedBarChartData} options={stackedBarChartOptions} />
      </div>
      <div className="chart-container">
        <h2>Line Chart</h2>
        <Line data={lineChartData} options={lineChartOptions} />
      </div>
      <div className="chart-container">
        <h2>Bar Chart</h2>
        <Bar data={barChartData} options={barChartOptions} />
      </div>
      <div className="chart-container">
        <h2>Pie Chart</h2>
        <Pie data={pieChartData} options={pieChartOptions} />
      </div>
    </div>
  );
};

export default Chart;