import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import pict from './unnamed (1).png';
import './LauncherIcon.css';
import './LauncherMenu.css';
import imga from './Coinss.png';
import API_URLS from './APIConfig';
import { Button } from "@mui/material";
import { logout } from './authUtils';

const Appheader = () => {
    const [menuOpen, setMenuOpen] = useState(false); 
    const [costSavings, setCostSavings] = useState(null);
    const [error, setError] = useState(null);
    const usenavigate = useNavigate();

    const services = [
        { name: 'EC2', link: '/chart', imgSrc: './assets/EC2.png' },  
        { name: 'S3', link: '#', imgSrc: './assets/s3.png' },
        { name: 'RDS', link: '#', imgSrc: './assets/RDS.png' },
        { name: 'Lambda', link: '/lambda', imgSrc: './assets/Lambda.png' },
        { name: 'CloudFront', link: '#', imgSrc: './assets/Cloudfront.png' },
        { name: 'SNS', link: '#', imgSrc: './assets/SNS.png' },
        { name: 'SQS', link: '#', imgSrc: './assets/SQS.png' },
        { name: 'ECS', link: '#', imgSrc: './assets/ECS.png' },
        { name: 'EKS', link: '#', imgSrc: './assets/EKS.png' },
        { name: 'Cost Explorer', link: '/cost-explorer', imgSrc: './assets/CostExplorer.png' }
    ];    

    useEffect(() => {
        const fetchCostSavings = async () => {
            try {
                const response = await axios.get(API_URLS.COST, {
                    timeout: 30000,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                
                if (response.data && response.data.body) {
                    setCostSavings(response.data.body);
                    setError(null);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (error) {
                console.error('Error fetching cost savings:', error);
                setError('Failed to fetch cost savings');
                setCostSavings(prev => prev || 0);
            }
        };

        fetchCostSavings();
        const intervalId = setInterval(fetchCostSavings, 300000);
        return () => clearInterval(intervalId);
    }, []);

    const handleServiceClick = (link) => {
        if (link !== '#') {
            usenavigate(link);
            setMenuOpen(false);
        }
    };

    const toggleLauncherMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = () => {
        // Clear session storage
        sessionStorage.clear();
        
        // Redirect to Cognito logout URL
        window.location.href = API_URLS.COGNITO_LOGOUT_URL;
    };

    return (
        <>
            <div className="header">
                <div className="header-left">
                    <Link to="/" className="logo-link">
                        <img src={pict} alt="Logo" className="Applogo" />
                    </Link>
                </div>

                <div className="header-right">
                    <div className="launcher-icon" onClick={toggleLauncherMenu}>
                        {[...Array(9)].map((_, i) => (
                            <div key={i} className="dot"></div>
                        ))}
                    </div>
                    <Button 
                        variant="contained" 
                        color="error" 
                        onClick={logout}
                        style={{ marginLeft: '10px', textTransform: 'none' }}
                    >
                        Logout
                    </Button>
                </div>
            </div>

            {menuOpen && (
                <div 
                    className={`launcher-menu-overlay ${menuOpen ? 'active' : ''}`} 
                    onClick={toggleLauncherMenu}
                >
                    <div 
                        className="launcher-menu" 
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="services-grid">
                            {services.map((service, index) => (
                                <div 
                                    key={index}
                                    className="service-item"
                                    onClick={() => handleServiceClick(service.link)}
                                >
                                    <img src={service.imgSrc} alt={service.name} />
                                    <span>{service.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Appheader;