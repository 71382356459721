import React, { useEffect, useState } from 'react';
import { Tabs } from '@mantine/core';
import classes from './demo.module.css';
import {
  IconCircleCheckFilled
} from '@tabler/icons-react';
import NonPaginatedTable from './NonPaginatedTable'; // Import your NonPaginatedTable component

// ----- HEADER MAPPINGS -----
const headerMappings = {
  table1: {
    'Instance Id': 'IID',
    instanceType: 'Instance Type',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table2: {
    instanceId: 'Instance ID',
    instanceType: 'Instance Type',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table3: {
    volumeId: 'Volume ID',
    volumeType: 'Volume Type',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table4: {
    volumeId: 'Volume ID',
    volumeType: 'Volume Type',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table5: {
    nicId: 'NIC ID',
    'Instance Id': 'Instance ID',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table6: {
    eipId: 'EIP ID',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table7: {
    amiId: 'AMI ID',
    region: 'Region',
    recommendation: 'Recommendation',
  },
  table8: {
    snapshotId: 'Snapshot ID',
    region: 'Region',
    recommendation: 'Recommendation',
  },
};

const mapHeaders = (data, tableKey) => {
  const mapping = headerMappings[tableKey];
  return data.map((row) => {
    const mappedRow = {};
    for (const [key, value] of Object.entries(row)) {
      const newKey = mapping[key] || key; // Replace key if mapping exists, otherwise keep original key
      mappedRow[newKey] = value;
    }
    return mappedRow;
  });
};

const Demo = () => {
  // 1. Read the tab from the URL on mount:
  const queryParams = new URLSearchParams(window.location.search);
  const initialTab = queryParams.get('tab') || 'tab1';

  // 2. Keep a piece of state for the active tab:
  const [activeTab, setActiveTab] = useState(initialTab);

  // ---- TABLE STATES ----
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table3Data, setTable3Data] = useState([]);
  const [table4Data, setTable4Data] = useState([]);
  const [table5Data, setTable5Data] = useState([]);
  const [table6Data, setTable6Data] = useState([]);
  const [table7Data, setTable7Data] = useState([]);
  const [table8Data, setTable8Data] = useState([]);

  // ---- COUNT STATES ----
  const [tableCounts, setTableCounts] = useState({
    table1: 0,
    table2: 0,
    table3: 0,
    table4: 0,
    table5: 0,
    table6: 0,
    table7: 0,
    table8: 0,
  });

  // Loading states for each table
  const [loading, setLoading] = useState({
    table1: true,
    table2: true,
    table3: true,
    table4: true,
    table5: true,
    table6: true,
    table7: true,
    table8: true,
  });

  // ---- FETCH FUNCTION ----
  const fetchData = async (url, tableKey, setData) => {
    try {
      const response = await fetch(url);
      const result = await response.json();
      console.log(`API response for ${tableKey}:`, result);

      if (result.statusCode === 200) {
        const mapping = headerMappings[tableKey];
        const mappedData = result.body.map((row) => {
          const mappedRow = {};
          for (const [key, value] of Object.entries(row)) {
            const newKey = mapping[key] || key;
            mappedRow[newKey] = value;
          }
          return mappedRow;
        });
        setData(mappedData);
        setTableCounts((prev) => ({ ...prev, [tableKey]: mappedData.length }));
      } else {
        console.error(`Error fetching data for ${tableKey}:`, result);
      }
    } catch (error) {
      console.error(`Error fetching data for ${tableKey}:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [tableKey]: false }));
    }
  };

  // ---- USEEFFECT TO FETCH TABLES ----
  useEffect(() => {
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ec2recommendation',
      'table1',
      setTable1Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ec2-migrate-to-graviton',
      'table2',
      setTable2Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ebs-right-size',
      'table3',
      setTable3Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ebs-upgrade',
      'table4',
      setTable4Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/nic-recommendation',
      'table5',
      setTable5Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/eip-recommendation',
      'table6',
      setTable6Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/ami-recommendation',
      'table7',
      setTable7Data
    );
    fetchData(
      'https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/snapshot-optimization',
      'table8',
      setTable8Data
    );
  }, []);

  // ---- UPDATE THE URL WHEN activeTab CHANGES ----
  useEffect(() => {
    // This will replace the current history entry with /demo?tab={activeTab}
    window.history.replaceState({}, '', `/demo?tab=${activeTab}`);
  }, [activeTab]);

  // ---- ACTION BUTTONS ----
  const handleEdit = (row) => {
    console.log('Edit button clicked. Row data:', row);
  };

  const handleDelete = (row) => {
    console.log('Delete button clicked. Row data:', row);
  };

  // ---- RENDER ----
  return (
    <div className="main-container">
      <Tabs
        variant="pills"
        value={activeTab}
        onChange={setActiveTab}
        keepMounted={false}
        style={{
          maxWidth: '70%',
          alignSelf: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
        classNames={classes}
      >
        <div style={{ padding: '12px', borderRadius: '12px' }} className='glassmorph'>
          <Tabs.List>
            <Tabs.Tab value="tab1">
              EC2 Recommendation
              {tableCounts.table1 > 0 && ` (${tableCounts.table1})`}
              {tableCounts.table1 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab2">
              Migrate to Graviton
              {tableCounts.table2 > 0 && ` (${tableCounts.table2})`}
              {tableCounts.table2 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab3">
              EBS Rightsize
              {tableCounts.table3 > 0 && ` (${tableCounts.table3})`}
              {tableCounts.table3 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab4">
              EBS Upgrade
              {tableCounts.table4 > 0 && ` (${tableCounts.table4})`}
              {tableCounts.table4 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab5">
              NIC Recommendation
              {tableCounts.table5 > 0 && ` (${tableCounts.table5})`}
              {tableCounts.table5 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab6">
              EIP Recommendation
              {tableCounts.table6 > 0 && ` (${tableCounts.table6})`}
              {tableCounts.table6 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab7">
              AMI Recommendation
              {tableCounts.table7 > 0 && ` (${tableCounts.table7})`}
              {tableCounts.table7 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
            <Tabs.Tab value="tab8">
              Snapshot Optimization
              {tableCounts.table8 > 0 && ` (${tableCounts.table8})`}
              {tableCounts.table8 === 0 && (
                <IconCircleCheckFilled style={{ marginLeft: '8px', color: 'var(--mantine-color-green-5)' }} />
              )}
            </Tabs.Tab>
          </Tabs.List>
        </div>

        {/* TAB 1: EC2 Recommendation */}
        <Tabs.Panel value="tab1">
          {loading.table1 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table1Data}
              maxColumns={5}
              columnMapping={headerMappings.table1}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 2: Migrate to Graviton */}
        <Tabs.Panel value="tab2">
          {loading.table2 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table2Data}
              maxColumns={5}
              columnMapping={headerMappings.table2}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 3: EBS Rightsize */}
        <Tabs.Panel value="tab3">
          {loading.table3 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table3Data}
              maxColumns={5}
              columnMapping={headerMappings.table3}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 4: EBS Upgrade */}
        <Tabs.Panel value="tab4">
          {loading.table4 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table4Data}
              maxColumns={5}
              columnMapping={headerMappings.table4}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 5: NIC Recommendation */}
        <Tabs.Panel value="tab5">
          {loading.table5 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table5Data}
              maxColumns={5}
              columnMapping={headerMappings.table5}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 6: EIP Recommendation */}
        <Tabs.Panel value="tab6">
          {loading.table6 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table6Data}
              maxColumns={5}
              columnMapping={headerMappings.table6}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 7: AMI Recommendation */}
        <Tabs.Panel value="tab7">
          {loading.table7 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table7Data}
              maxColumns={5}
              columnMapping={headerMappings.table7}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>

        {/* TAB 8: Snapshot Optimization */}
        <Tabs.Panel value="tab8">
          {loading.table8 ? (
            <p>Loading...</p>
          ) : (
            <NonPaginatedTable
              data={table8Data}
              maxColumns={5}
              columnMapping={headerMappings.table8}
              actionButtons={[
                { label: 'Edit', onClick: handleEdit },
                { label: 'Delete', onClick: handleDelete },
              ]}
            />
          )}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default Demo;
