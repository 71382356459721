import React, { useState } from 'react';
import { Table, Pagination, TextInput, Button } from '@mantine/core';
import dayjs from 'dayjs';
import { IconSort09, IconSort90, IconSortAZ, IconSortZA, IconSearch } from '@tabler/icons-react';

const PaginatedTable = ({
  data,
  selectedDimension,
  disableSticky = false,
  disableScroll = false,
  disableDecimalFormatting = false,
  actionButtons = [], // New prop
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ column: null, direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 10;

  // Format date ranges as "Jan 1 - 4, 2024"
  const formatDateRange = (dateRange) => {
    const [startDate, endDate] = dateRange.split(' to ');
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return `${start.format('MMM D')} - ${end.format('D, YYYY')}`;
  };

  // Extract dimension keys dynamically
  const dimensions = Object.keys(data[0]).filter((key) => key !== 'month');

  // Create rows: Total Cost + Dimensions
  const allRows = [
    {
      label: 'Total Cost',
      values: data.map((row) =>
        dimensions.reduce((sum, dim) => sum + (row[dim] || 0), 0)
      ),
    },
    ...dimensions.map((dimension) => ({
      label: dimension,
      values: data.map((row) => row[dimension] || 0),
    })),
  ];

  // Filter rows based on search query
  const filteredRows = allRows.filter((row) =>
    row.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort rows based on configuration
  const sortRows = (rows) => {
    if (!sortConfig.column) return rows;

    return [...rows].sort((a, b) => {
      if (sortConfig.column === selectedDimension) {
        return sortConfig.direction === 'asc'
          ? a.label.localeCompare(b.label)
          : b.label.localeCompare(a.label);
      }

      const colIndex = data.findIndex((row) => formatDateRange(row.month) === sortConfig.column);
      const aValue = a.values[colIndex] || 0;
      const bValue = b.values[colIndex] || 0;

      return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
    });
  };

  const sortedRows = sortRows(filteredRows);

  // Calculate pagination for rows
  const totalPages = Math.ceil(sortedRows.length / itemsPerPage);
  const paginatedRows = sortedRows.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Update sorting configuration
  const handleSort = (column) => {
    setSortConfig((prev) => ({
      column,
      direction: prev.column === column && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Get sort icon dynamically
  const getSortIcon = (column) => {
    if (sortConfig.column !== column) {
      return null;
    }

    if (column === selectedDimension) {
      return sortConfig.direction === 'asc' ? <IconSortAZ size={24} /> : <IconSortZA size={24} />;
    }

    return sortConfig.direction === 'asc' ? <IconSort09 size={24} /> : <IconSort90 size={24} />;
  };

  return (
    <div>
      {/* Search Field */}
      <TextInput
        placeholder="Search rows..."
        icon={<IconSearch />}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: '20px' }}
      />

      {/* Table */}
      <Table.ScrollContainer
        className="glassmorph-lv1"
        style={{
          color: 'black',
          borderRadius: '12px',
          overflowX: disableScroll ? 'unset' : 'auto',
        }}
        minWidth={disableScroll ? undefined : 500}
      >
        <Table highlightOnHover withTableBorder horizontalSpacing="lg">
          <Table.Thead>
            <Table.Tr style={{ whiteSpace: 'nowrap' }}>
              <Table.Th
                className="opaque-glass"
                style={{
                  position: disableSticky ? 'static' : 'sticky',
                  left: -1,
                  zIndex: 2,
                }}
              >
                <div
                  className="pointer-pointer"
                  onClick={() => handleSort(selectedDimension)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                  }}
                >
                  <span>{selectedDimension.toLowerCase()}</span>
                  {getSortIcon(selectedDimension)}
                </div>
              </Table.Th>
              {data.map((row) => (
                <Table.Th
                  key={row.month}
                  style={{
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <div
                    className="pointer-pointer"
                    onClick={() => handleSort(formatDateRange(row.month))}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span>{formatDateRange(row.month)}</span>
                    {getSortIcon(formatDateRange(row.month))}
                  </div>
                </Table.Th>
              ))}
              {actionButtons.length > 0 && <Table.Th>Actions</Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {paginatedRows.map((row) => (
              <Table.Tr key={row.label} style={{ whiteSpace: 'nowrap' }}>
                <Table.Td
                  className="opaque-glass"
                  style={{
                    position: disableSticky ? 'static' : 'sticky',
                    left: -1,
                    zIndex: 1,
                  }}
                >
                  <strong>{row.label}</strong>
                </Table.Td>
                {row.values.map((value, index) => (
                  <Table.Td key={index} style={{ textAlign: 'right' }}>
                    {disableDecimalFormatting ? `$${value}` : `$${value.toFixed(2)}`}
                  </Table.Td>
                ))}
                {actionButtons.length > 0 && (
                  <Table.Td>
                    {actionButtons.map((button, index) => (
                      <Button
                        key={index}
                        onClick={() => button.onClick(row)}
                        variant="outline"
                        size="xs"
                        style={{ marginRight: '5px' }}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </Table.Td>
                )}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      {/* Pagination Controls */}
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
        <Pagination
          total={totalPages}
          value={currentPage}
          onChange={setCurrentPage}
          size="md"
          radius="sm"
          withControls
          withEdges
        />
      </div>
    </div>
  );
};

export default PaginatedTable;
