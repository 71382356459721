import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Organization.css';

const Organization = () => {
    const [organizationName, setOrganizationName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Define the API endpoint for the Lambda function
        const lambdaApiUrl = 'https://5jccgx4tw1.execute-api.us-east-1.amazonaws.com/AuthSSOByAnkit';
        // const lambdaApiUrl = process.env.REACT_APP_LAMBDA_API_URL;

        try {
            // Send the organization name to the Lambda function
            const response = await fetch(lambdaApiUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ organizationName })
            });

            const result = await response.json();

            if (response.ok && result.message === 'Authentication successful') {
                // If authentication is successful, navigate to the home page
                navigate('/');
            } else {
                // If the organization is invalid, show an error message
                setErrorMessage(result.error || 'Invalid organization name or not associated with an identity provider.');
            }
        } catch (error) {
            console.error('Error during organization validation:', error);
            setErrorMessage('Error during organization validation.');
        }
    };

    return (
        <div className="main">
            <p className="sign" align="center">Choose Your Organization</p>
            <form className="form1" onSubmit={handleSubmit}>
                <input
                    className="un"
                    type="text"
                    align="center"
                    placeholder="Organization Name"
                    value={organizationName}
                    onChange={(e) => setOrganizationName(e.target.value)}
                />
                <button className="submit" align="center">Submit</button>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </form>
        </div>
    );
};

export default Organization;
