import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import _ from 'lodash';
import { Typography } from '@mui/material';
import ReactTooltip from 'react-tooltip';
// import './Home.css'; 
import './Home.enhanced.css'; 
import API_URLS from './APIConfig';



const Home = () => {
  const [ec2Result, setEc2Result] = useState([]);
  const [ebsResult, setEbsResult] = useState([]);
  const [ec2MigrateResult, setEc2MigrateResult] = useState([]);
  const [currentPageEc2Migrate, setCurrentPageEc2Migrate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ec2StopResult, setEc2StopResult] = useState([]);
  const [currentPageEC2Stop, setCurrentPageEC2Stop] = useState(0);
  const [migrateLoading, setMigrateLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [nicResult, setNicResult] = useState([]); // New state for NIC recommendations
  const [currentPageEBS, setCurrentPageEBS] = useState(0);
  const [currentPageNIC, setCurrentPageNIC] = useState(0);
  const [amiResult, setAmiResult] = useState([]);
  const [eipResult, setEipResult] = useState([]);
  const [currentPageEIP, setCurrentPageEIP] = useState(0);
  const [currentPageAMI, setCurrentPageAMI] = useState(0);
  const [snapshotResult, setSnapshotResult] = useState([]);
  const [currentPageSnapshot, setCurrentPageSnapshot] = useState(0); // for pagination
  const [rightSizeResult, setRightSizeResult] = useState([]);
  const [currentPageRightSize, setCurrentPageRightSize] = useState(0); // Pagination for Right Size table
  const [ebsUpgradeResult, setEbsUpgradeResult] = useState([]);
  const [currentPageEbsUpgrade, setCurrentPageEbsUpgrade] = useState(0);
  

  const itemsPerPage = 10;
  const itemsPerPageEc2Migrate = 10;
  const itemsPerPageEC2Stop = 10;
  const itemsPerPageEBS = 10;
  const itemsPerPageNIC = 10;
  const itemsPerPageEIP = 10;
  const itemsPerPageRightSize = 10;
  const itemsPerPageEbsUpgrade = 10;


  useEffect(() => {
    fetchEC2Recommendations();
    fetchEc2MigrateToGravitonRecommendations();
    fetchEC2StopRecommendations();
    fetchEBSRecommendations();
    fetchEBSUpgradeRecommendations();
    fetchRightSizeRecommendations();
    fetchNICRecommendations(); 
    fetchAMIRecommendations();
    fetchEIPRecommendations();
    fetchSnapshotRecommendations();
  }, []);

  const fetchEC2Recommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EC2_RECOMMENDATION;
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setEc2Result(response.data.body);
    } catch (err) {
        setError('Error fetching EC2 recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};


  const fetchEc2MigrateToGravitonRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EC2_MIGRATE_TO_GRAVITON; // Using the URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setEc2MigrateResult(response.data.body);
    } catch (err) {
        setError('Error fetching EC2 migrate to Graviton recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};


  const fetchEC2StopRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EC2_STOP_RECOMMENDATION; // Using the URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setEc2StopResult(response.data.body);
    } catch (err) {
        setError('Error fetching EC2 stop recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};

  const fetchEBSRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EBS_RECOMMENDATION; // Using the URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setEbsResult(response.data.body);
    } catch (err) {
        setError('Error fetching EBS recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};

  const fetchRightSizeRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EBS_RIGHT_SIZING; // Using the URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
        });
        setRightSizeResult(response.data.body);
    } catch (err) {
        setError('Error fetching EBS Right Size recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};


  const fetchEBSUpgradeRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EBS_UPGRADE_RECOMMENDATION; // Using the updated URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setEbsUpgradeResult(response.data.body);
    } catch (err) {
        setError('Error fetching EBS upgrade recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};

  const fetchNICRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.NIC_RECOMMENDATION; // Using the updated URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setNicResult(response.data.body);
    } catch (err) {
        setError('Error fetching NIC recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};
  
  
  const fetchAMIRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.AMI_RECOMMENDATION; // Using the updated URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setAmiResult(response.data.body);
    } catch (err) {
        setError('Error fetching AMI recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};
  
  const fetchEIPRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.EIP_RECOMMENDATION; // Using the updated URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setEipResult(response.data.body); // Store EIP recommendations
    } catch (err) {
        setError('Error fetching EIP recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};

  const fetchSnapshotRecommendations = async () => {
    setLoading(true);
    setError(null);
    try {
        const url = API_URLS.SNAPSHOT; // Using the updated URL from APIConfig
        console.log("Fetching from URL:", url);

        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        setSnapshotResult(response.data.body);
    } catch (err) {
        setError('Error fetching snapshot recommendations. Please check the console for more details.');
        console.error(err);
    }
    setLoading(false);
};




  const handleInstanceMigrate = async (instanceId, region, recommendedInstanceType) => {
    setMigrateLoading((prev) => ({ ...prev, [instanceId]: true }));
    try {
      const url = API_URLS.HANDLE_INSTANCE_MIGRATE; // Using the updated URL from APIConfig
      const response = await axios.get(url, {
          params: {
              InstanceId: instanceId,
              Region: region,
              RecommendedInstanceType: recommendedInstanceType
          },
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      toast.success(`Instance ${instanceId} migrated successfully!`);

    } catch (err) {
      console.error(`Error migrating instance ${instanceId}:`, err);
      setError(`Error migrating instance ${instanceId}.`);
    }
    setMigrateLoading((prev) => ({ ...prev, [instanceId]: false }));
  };

  const handleBackup = async (volumeId) => {
    try {
      const url = API_URLS.HANDLE_BACKUP; // Using the updated URL from APIConfig
      const response = await axios.get(url, {
          params: {
              VolumeId: volumeId
          },
          headers: {
              'Content-Type': 'application/json'
          }
      });
      toast.success(`Backup for Volume ${volumeId} initiated successfully!`);

    } catch (error) {
      toast.error(`Failed to initiate backup for Volume ${volumeId}.`);
      console.error('Backup error:', error);
    }
  };

  const handleDeleteSnapshot = async (volumeId) => {
    try {
      const url = API_URLS.DELETE_EBS_SNAPSHOT; // Using the updated URL from APIConfig
      const response = await axios.get(url, {
          params: {
              VolumeId: volumeId
          },
          headers: {
              'Content-Type': 'application/json'
          }
      });
      toast.success(`Volume ${volumeId} deleted successfully!`);
      fetchEBSRecommendations(); // Refresh the EBS recommendations after deletion


    } catch (error) {
      toast.error(`Failed to delete snapshot ${volumeId}.`);
      console.error('Delete snapshot error:', error);
    }
    
  };

  const handleDeleteNIC = async (networkInterfaceId) => {
  try {
    const response = await axios.get(
      ' https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/delete-nic', 
      {
        params: { NetworkInterfaceId: networkInterfaceId }, // Pass Network Interface ID
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    toast.success(`Network Interface ${networkInterfaceId} deleted successfully!`);
    fetchNICRecommendations(); // Refresh the NIC recommendations after deletion
  } catch (error) {
    toast.error(`Failed to delete Network Interface ${networkInterfaceId}.`);
    console.error('Delete NIC error:', error);
  }
};

  const handleDeleteEC2StopRecommendation = async (resourceId) => {
    try {
      const url = API_URLS.DELETE_EC2_STOP_RECOMMENDATION; // Using the updated URL from APIConfig
      const response = await axios.get(url, {
          params: {
              ResourceId: resourceId
          },
          headers: {
              'Content-Type': 'application/json'
          }
      });
      toast.success(`EC2 Stop Recommendation ${resourceId} deleted successfully!`);
      fetchEC2StopRecommendations(); // Refresh the EC2 Stop recommendations after deletion


    } catch (error) {
      toast.error(`Failed to delete EC2 Stop Recommendation ${resourceId}.`);
      console.error('Delete EC2 Stop Recommendation error:', error);
    }
    
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value);

  const handleCreateAMI = async (instanceId) => {
    try {
      const url = API_URLS.CREATE_AMI; // Using the updated URL from APIConfig
      const response = await axios.get(url, {
          params: {
              InstanceId: instanceId
          },
          headers: {
              'Content-Type': 'application/json'
          }
      });
      toast.success(`AMI creation initiated for Instance ${instanceId}!`);
    } catch (error) {
      toast.error(`Failed to create AMI for Instance ${instanceId}.`);
      console.log('Error message:', error.message);
      if (error.response) {
        console.log('Error status:', error.response.status);
        console.log('Error data:', error.response.data);
        console.log('Error headers:', error.response.headers);
      } else {
        console.log('Error details:', error);
      }
  
      console.error('Create AMI error:', error);
    }
  };
  
  const handleReleaseEIP = async (allocationId) => {
    try {
      const apiUrl = API_URLS.DELETE_EIP; // Using the updated URL from APIConfig
      const params = { AllocationId: allocationId };
      
      // Log the URL and parameters
      console.log('Executing API request:', apiUrl, 'with parameters:', params);

      const response = await axios.get(apiUrl, { params });

      toast.success(`EIP with AllocationId ${allocationId} released successfully!`);
      fetchEIPRecommendations(); // Refresh EIP recommendations after release


    } catch (error) {
      toast.error(`Failed to release EIP with AllocationId ${allocationId}.`);
      console.error('Release EIP error:', error.message);
    }
  };

  const renderEC2Table = () => {
    if (!Array.isArray(ec2Result) || ec2Result.length === 0) return null;
  
    const filteredData = ec2Result.filter(item =>
      Object.values(item).some(val =>
        val.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    const paginatedData = _.chunk(filteredData, itemsPerPage)[currentPage] || [];
    const keys = filteredData.length > 0 ? Object.keys(filteredData[0]) : [];

    return (
      <div className="table-container" >
        <h2 className="table-title">EC2 Recommendations</h2>
        {showSearch && (
          <div className="search-container">
            <input
              type="text"
              placeholder="Search in EC2 Recommendations"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                marginBottom: '10px',
                boxSizing: 'border-box',
                transition: 'width 0.3s ease-in-out',
              }}
            />
          </div>
        )}
        <table className="data-table" style={{ fontSize: '12px', borderColor: 'black' }}>
          <thead>
            <tr>
              {keys.map(key => (
                <th key={key}>{key}</th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr
                key={index}
                style={{
                  // backgroundColor: index % 2 === 0 ? '#ffffff' : '#E0E8F1', // Alternate colors
                  textAlign: 'center',
                }}
              >
                {keys.map(key => (
                  <td key={key}>{item[key]}</td>
                ))}
                <td>
                  <button 
                    className="migrate-button"
                    onClick={() =>
                      handleInstanceMigrate(item['Instance Id'], item['Region'], item['Recommended Instance Type'])
                    } 
                    disabled={migrateLoading[item['Instance Id']]}
                  >
                    {migrateLoading[item['Instance Id']] ? 'Migrating...' : 'Migrate'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(filteredData.length / itemsPerPage)}
          onPageChange={({ selected }) => setCurrentPage(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };

  const renderEc2MigrateTable = () => {
    if (!Array.isArray(ec2MigrateResult) || ec2MigrateResult.length === 0) return null;
  
    const paginatedData = _.chunk(ec2MigrateResult, itemsPerPageEc2Migrate)[currentPageEc2Migrate] || [];
    const keys = ec2MigrateResult.length > 0 ? Object.keys(ec2MigrateResult[0]) : [];
  
    return (
      <div className="table-container">
        <h2 className="table-title">EC2 Migrate to Graviton Recommendations</h2>
        <table className="data-table" style={{ fontSize: '13px', borderRadius: '10px !important' }}>
          <thead>
            <tr>
              {keys.map(key => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index}>
                {keys.map(key => (
                  <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(ec2MigrateResult.length / itemsPerPageEc2Migrate)}
          onPageChange={({ selected }) => setCurrentPageEc2Migrate(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };

  const renderEC2StopTable = () => {
    if (!Array.isArray(ec2StopResult) || ec2StopResult.length === 0) return null;

    const paginatedData = _.chunk(ec2StopResult, itemsPerPageEC2Stop)[currentPageEC2Stop] || [];
    const keys = ec2StopResult.length > 0 ? Object.keys(ec2StopResult[0]) : [];

    return (
      <div className="table-container">
        <h2 className="table-title">EC2 Stop Recommendations</h2>
        <table className="data-table" style={{ fontSize: '13px', borderRadius: '10px' }}>
          <thead>
            <tr>
              {keys.map((key) => (
                <th key={key}>{key}</th>
              ))}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index}>
                {keys.map((key) => (
                  <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                ))}
                <td>
                  <button 
                    className="delete-button"
                    onClick={() =>
                      handleDeleteEC2StopRecommendation(item['Resource Id'])
                    } 
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(ec2StopResult.length / itemsPerPageEC2Stop)}
          onPageChange={({ selected }) => setCurrentPageEC2Stop(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };

  const renderEBSTable = () => {
    if (!Array.isArray(ebsResult) || ebsResult.length === 0) return null;
  
    const paginatedData = _.chunk(ebsResult, itemsPerPageEBS)[currentPageEBS] || [];
    const keys = ebsResult.length > 0 ? Object.keys(ebsResult[0]) : [];
  
    return (
      <div className="table-container">
        <h2 className="table-title">EBS Recommendations</h2>
        <table className="data-table" style={{ fontSize: '12px' }}>
          <thead>
            <tr>
              {keys.map((key) => (
                <th key={key}>{key}</th>
              ))}
              <th>Backup</th>
              {<th>Delete</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index}>
                {keys.map((key) => (
                  <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                ))}
                <td style={{ textAlign: 'center' }}>
                  {item['Snapshot ID'] === 'Unavailable' ? (
                    <button
                  className="backup-button"
                  style={{
                    backgroundColor: item['LockValue'] === 1 ? 'grey' : '#0AE289', // Conditional background color
                    cursor: item['LockValue'] === 1 ? 'not-allowed' : 'pointer' // Change cursor when disabled
                  }}
                  onClick={() => item['LockValue'] !== 1 && handleBackup(item['Volume ID'])} // Only trigger onClick if not locked
                  disabled={item['LockValue'] === 1} // Disable button when LockValue is 1
                >
  <img src="https://icons.iconarchive.com/icons/icons8/windows-8/512/Database-Backup-icon.png" alt="Delete Icon" style={{ width: '16px', height: '16px' }} />
                      &nbsp; Backup
                    </button>
                  ) : (
                    'N.A'
                  )}
                </td>
                {(
                  <td>
                    {item['Volume ID'] && item['Volume ID'] !== '' && (
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteSnapshot(item['Volume ID'])}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/128/9790/9790368.png"
                          alt="Delete Icon"
                          style={{ width: '16px', height: '16px' }}
                        />
                        &nbsp; Delete
                      </button>
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(ebsResult.length / itemsPerPageEBS)}
          onPageChange={({ selected }) => setCurrentPageEBS(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };
  
    // Render the EBS Right Size Recommendations Table
    const renderRightSizeTable = () => {
      if (!Array.isArray(rightSizeResult) || rightSizeResult.length === 0) return null;
  
      const paginatedData = _.chunk(rightSizeResult, itemsPerPageRightSize)[currentPageRightSize] || [];
      const keys = rightSizeResult.length > 0 ? Object.keys(rightSizeResult[0]) : [];
  
      return (
        <div className="table-container">
          <h2 className="table-title">EBS Right Size Recommendations</h2>
          <table className="data-table" style={{ fontSize: '13px', borderRadius: '10px' }}>
            <thead>
              <tr>
                {keys.map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index}>
                  {keys.map((key) => (
                    <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            pageCount={Math.ceil(rightSizeResult.length / itemsPerPageRightSize)}
            onPageChange={({ selected }) => setCurrentPageRightSize(selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
          />
        </div>
      );
    };


    const renderEBSUpgradeTable = () => {
      if (!Array.isArray(ebsUpgradeResult) || ebsUpgradeResult.length === 0) return null;
    
      const paginatedData = _.chunk(ebsUpgradeResult, itemsPerPageEbsUpgrade)[currentPageEbsUpgrade] || [];
      const keys = ebsUpgradeResult.length > 0 ? Object.keys(ebsUpgradeResult[0]) : [];
    
      return (
        <div className="table-container">
          <h2 className="table-title">EBS Upgrade Recommendations</h2>
          <table className="data-table" style={{ fontSize: '13px', borderRadius: '10px !important' }}>
            <thead>
              <tr>
                {keys.map(key => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index}>
                  {keys.map(key => (
                    <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            pageCount={Math.ceil(ebsUpgradeResult.length / itemsPerPageEbsUpgrade)}
            onPageChange={({ selected }) => setCurrentPageEbsUpgrade(selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
          />
        </div>
      );
    };
    

  const renderNICRecommendationsTable = () => {
    if (!Array.isArray(nicResult) || nicResult.length === 0) return null;
  
    const paginatedData = _.chunk(nicResult, itemsPerPageNIC)[currentPageNIC] || [];
    const keys = nicResult.length > 0 ? Object.keys(nicResult[0]) : [];
  
    return (
      <div className="table-container">
        <h2 className="table-title">NIC Recommendations</h2>
        <table className="data-table" style={{ fontSize: '13px', borderRadius: '10px !important' }}>
          <thead>
            <tr>
              {keys.map(key => (
                <th key={key}>{key}</th>
              ))}
              {<th>Delete</th>} {/* Add Delete column if admin has access */}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index}>
                {keys.map(key => (
                  <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                ))}
                {(
                  <td style={{ textAlign: 'center' }}>
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteNIC(item['Network Interface ID'])} // Pass Network Interface ID
                    >
                      <img src="https://cdn-icons-png.flaticon.com/128/9790/9790368.png" alt="Delete Icon" style={{ width: '12px', height: '12px' }} />
                      &nbsp; Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(nicResult.length / itemsPerPageNIC)}
          onPageChange={({ selected }) => setCurrentPageNIC(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };
  
  const itemsPerPageAMI = 10;

  const renderAMITable = () => {
    if (!Array.isArray(amiResult) || amiResult.length === 0) return null;
  
    const paginatedData = _.chunk(amiResult, itemsPerPageAMI)[currentPageAMI] || [];
    const keys = amiResult.length > 0 ? Object.keys(amiResult[0]) : [];
  
    return (
      <div className="table-container">
        <h2 className="table-title">AMI Recommendations</h2>
        <table className="data-table" style={{fontSize:'13px' , borderRadius:'10px !important' }}>
          <thead>
            <tr>
              {keys.map(key => (
                <th key={key}>{key}</th>
              ))}
              <th>Create AMI</th> {/* Add this header */}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index}>
                {keys.map(key => (
                  <td key={key} style={{textAlign:'center'}}>{item[key]}</td>
                ))}
                <td style={{textAlign:'center'}}>
                  <button
                    className="create-ami-button"
                    onClick={() => handleCreateAMI(item['Instance ID'])}
                  >
                    Create AMI
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(amiResult.length / itemsPerPageAMI)}
          onPageChange={({ selected }) => setCurrentPageAMI(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };
  
const renderEIPTable = () => {
    if (!Array.isArray(eipResult) || eipResult.length === 0) return null;
  
    const paginatedData = _.chunk(eipResult, itemsPerPageEIP)[currentPageEIP] || [];
    const keys = eipResult.length > 0 ? Object.keys(eipResult[0]) : [];
  
    return (
      <div className="table-container">
        <h2 className="table-title">EIP Recommendations</h2>
        <table className="data-table" style={{ fontSize: '13px', borderRadius: '10px', textAlign: 'justify' }}>
          <thead>
            <tr>
              {keys.map((key) => (
                <th key={key}>{key}</th>
              ))}
              <th>Release</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((item, index) => (
              <tr key={index}>
                {keys.map((key) => (
                  <td key={key} style={{ textAlign: 'center' }}>{item[key]}</td>
                ))}
                <td style={{ textAlign: 'center' }}>
                  <button
                    className="release-button"
                    onClick={() => handleReleaseEIP(item['Allocation ID'])}
                  >
                    Release🚀
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginate
          pageCount={Math.ceil(eipResult.length / itemsPerPageEIP)}
          onPageChange={({ selected }) => setCurrentPageEIP(selected)}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
        />
      </div>
    );
  };
  const renderSnapshotTable = () => {
    if (!Array.isArray(snapshotResult) || snapshotResult.length === 0) return null;
  
    const paginatedData = _.chunk(snapshotResult, itemsPerPage)[currentPageSnapshot] || [];
    const keys = snapshotResult.length > 0 ? Object.keys(snapshotResult[0]) : [];
  
    // Helper function to parse snapshot date strings into Date objects
    const parseSnapshotDate = (dateStr) => {
      const [day, month, year, time] = dateStr.split(/[-\s:]+/);
      return new Date(`${year}-${month}-${day}T${time}:00`);
    };
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
        <Typography variant="h5" gutterBottom style={{ color: 'white' }}>
          Snapshot Recommendations
        </Typography>

        <div style={{ width: '95%', overflowX: 'auto', display: 'block', borderRadius: '8px', padding: '20px' }}>
          <table className="data-table" style={{ fontSize: '13px', minWidth: '1200px', width: '100%', backgroundColor: 'white', 
  borderRadius: '8px'  }}>
            <thead>
              <tr>
                <th style={{ width: '8%' }}>LockValue</th>
                <th style={{ width: '15%' }}>VolumeId</th>
                <th style={{ width: '12%' }}>Volume Name</th>
                <th style={{ width: '15%' }}>SnapshotId: SnapshotTime</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => {
                // Find the latest snapshot time for this row
                let latestSnapshot = null;
                if (item['SnapshotId: SnapshotTime']) {
                  latestSnapshot = item['SnapshotId: SnapshotTime'].reduce(
                    (latest, current) => {
                      const snapshotId = Object.keys(current)[0];
                      const snapshotTime = current[snapshotId];
                      if (
                        !latest ||
                        parseSnapshotDate(snapshotTime) >
                          parseSnapshotDate(latest.time)
                      ) {
                        return { id: snapshotId, time: snapshotTime };
                      }
                      return latest;
                    },
                    null
                  );
                }
    
                return (
                  <tr key={index}>
                    {keys.map((key) => (
                      <td key={key} style={{ textAlign: 'center' }}>
                        {key === 'SnapshotId: SnapshotTime' &&
                        Array.isArray(item[key]) ? (
                          <div>
                            {item[key].map((snapshot, idx) => {
                              const snapshotId = Object.keys(snapshot)[0];
                              const snapshotTime = snapshot[snapshotId];
                              const isLatest = snapshotId === latestSnapshot?.id;
    
                              return (
                                <div
                                  key={idx}
                                  style={{
                                    marginBottom: '10px',
                                  }}
                                >
                                  {`"${snapshotId}" ↦ "🕔${snapshotTime}"`}
                                  <button
                                    style={{
                                      marginLeft: '10px',
                                      backgroundColor: isLatest
                                        ? '#0000FF' // Light blue for Latest
                                        : '#e7625f', // Red for Delete
                                      border: isLatest
                                        ? '1px solid #add8e6'
                                        : '1px solid #ff4d4d',
                                      borderRadius: '8px',
                                      color: '#fff',
                                      padding: '5px 10px',
                                      cursor: 'pointer',
                                      marginTop: '5px',
                                    }}
                                    onClick={
                                      isLatest
                                        ? undefined // No action for "Latest" button
                                        : () => handleDeleteSnapshot(snapshotId)
                                    }
                                  >
                                    {isLatest ? 'Latest' : 'Delete'}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          item[key]
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ReactPaginate
            pageCount={Math.ceil(snapshotResult.length / itemsPerPage)}
            onPageChange={({ selected }) => setCurrentPageSnapshot(selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
          />
        </div>
      </div>
    );
  };

  return (
    
    <div className="home">
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
      {renderEC2Table()}
      {renderEc2MigrateTable()}
      {renderEC2StopTable()}
      {renderEBSTable()}
      {renderRightSizeTable()}
      {renderEBSUpgradeTable()}
      {renderNICRecommendationsTable()}
      {renderAMITable()}
      {renderEIPTable()}
      {renderSnapshotTable()}
      
    </div>
  );
};

export default Home;