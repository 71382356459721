import React, { useEffect, useState } from "react";
import { BarChart } from "@mantine/charts";
import { Select, Loader, Checkbox, Button } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import './CostExplorer.css';
import PaginatedTable from "./PaginatedTable";
import { Alert } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import FiltersDropdown from './FiltersDropdown';
import filterData from './filter-options.json';

const CostDataGraph = () => {
  const [dimension, setDimension] = useState("REGION");
  const [granularity, setGranularity] = useState("MONTHLY");
  const [fromDate, setFromDate] = useState(new Date("2024-01-01"));
  const [toDate, setToDate] = useState(new Date("2024-03-31"));
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const dimensionOptions = [
    { value: "SERVICE", label: "Service" },
    { value: "LINKED_ACCOUNT", label: "Linked Account" },
    { value: "REGION", label: "Region" },
    { value: "INSTANCE_TYPE", label: "Instance Type" },
    { value: "INSTANCE_TYPE_FAMILY", label: "Instance Type Family" },
    { value: "USAGE_TYPE", label: "Usage Type" },
    { value: "USAGE_TYPE_GROUP", label: "Usage Type Group" },
    { value: "OPERATION", label: "Operation" },
    { value: "AZ", label: "Availability Zone" },
    { value: "PLATFORM", label: "Platform" },
    { value: "PURCHASE_TYPE", label: "Purchase Type" },
    { value: "TENANCY", label: "Tenancy" },
    { value: "DATABASE_ENGINE", label: "Database Engine" },
    { value: "BILLING_ENTITY", label: "Billing Entity" },
    { value: "LEGAL_ENTITY_NAME", label: "Legal Entity Name" },
  ];

  const granularityOptions = [
    { value: "MONTHLY", label: "Monthly" },
    { value: "DAILY", label: "Daily" },
  ];

  const [selectedFilters, setSelectedFilters] = useState({
    "filter-service": [],
    "filter-linked-account": [],
    "filter-region": [],
    "filter-instance-type": [],
    "filter-usage-type": [],
    "filter-usage-type-group": [],
    "filter-resource": [],
    "filter-cost-category": [],
    "filter-tag": [],
    "filter-charge-type": [],
    "filter-availability-zone": [],
    "filter-platform": [],
    "filter-purchase-option": [],
    "filter-tenancy": [],
    "filter-database-engine": [],
    "filter-legal-entity": [],
    "filter-billing-entity": [],
    "filter-api-operation": [],
  });

  const [lastFilters, setLastFilters] = useState(null);

  useEffect(() => {
    console.log('Selected filters changed:', selectedFilters);

    setLastFilters(selectedFilters);
  }, [selectedFilters]);

  const translateKey = (key) => {
    const keyMap = {
      "filter-service": "SERVICE",
      "filter-linked-account": "LINKED_ACCOUNT",
      "filter-region": "REGION",
      "filter-instance-type": "INSTANCE_TYPE",
      "filter-usage-type": "USAGE_TYPE",
      "filter-usage-type-group": "USAGE_TYPE_GROUP",
      "filter-resource": "RESOURCE",
      "filter-cost-category": "COST_CATEGORY",
      "filter-tag": "TAG",
      "filter-charge-type": "CHARGE_TYPE",
      "filter-availability-zone": "AVAILABILITY_ZONE",
      "filter-platform": "PLATFORM",
      "filter-purchase-option": "PURCHASE_OPTION",
      "filter-tenancy": "TENANCY",
      "filter-database-engine": "DATABASE_ENGINE",
      "filter-legal-entity": "LEGAL_ENTITY",
      "filter-billing-entity": "BILLING_ENTITY",
      "filter-api-operation": "API_OPERATION"
    };
    return keyMap[key] || key.toUpperCase(); 
  };

  const [clearAll, setClearAll] = useState(false); 

  const handleClearAll = () => {
    setClearAll(true); 
  };

  useEffect(() => {
    if (clearAll) {
      const allFiltersEmpty = Object.values(selectedFilters).every(
        (filter) => filter.length === 0
      );

      if (allFiltersEmpty) {
        setClearAll(false);
      }
    }
  }, [clearAll, selectedFilters]);


  const translateValue = (value) => {
    const valueMap = {
      "Amazon S3": "Amazon Simple Storage Service",
      // Other mappings
    };
    return valueMap[value] || value;
  };

  // API Fetch
  useEffect(() => {
    const fetchData = async () => {
      const currentFilters = {
        selectedFilters,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        granularity,
        dimension,
      };

      if (JSON.stringify(lastFilters) === JSON.stringify(currentFilters)) return;
      setLastFilters(currentFilters);

      if (fromDate > toDate) {
        setError("The 'From Date' cannot be after the 'To Date'.");
        setData([]);
        return;
      }

      setData([]);

      try {
        const baseURL = `https://pwce3zabo0.execute-api.us-east-1.amazonaws.com/dev/cost`;
        const queryParams = new URLSearchParams({
          dimension,
          granularity,
          start_date: fromDate.toISOString().split("T")[0],
          end_date: toDate.toISOString().split("T")[0],
        });

        Object.keys(selectedFilters).forEach((filterKey) => {
          const values = selectedFilters[filterKey];
          if (values.length > 0) {
            const translatedKey = translateKey(filterKey);
            const translatedValues = values.map(translateValue).join(",");
            queryParams.append(
              `filter_key_${translatedKey.toLowerCase()}`,
              translatedKey
            );
            queryParams.append(
              `filter_values_${translatedKey.toLowerCase()}`,
              translatedValues
            );
          }
        });

        const finalURL = `${baseURL}?${queryParams.toString()}`;
        console.log("API URL:", finalURL);

        const response = await fetch(finalURL);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();

        if (!result?.body || result.body === "undefined") {
          setError("No record found. Please try setting a different date range.");
          setData([]);
          return;
        }

        const parsedBody = JSON.parse(result.body);

        if (!parsedBody || parsedBody.length === 0) {
          setError("No record found. Please try setting a different date range.");
          setData([]);
          return;
        }

        const processedData = processCostData(parsedBody);
        setData(processedData);
        setError(null);
      } catch (err) {
        setError(err.message || "An unknown error occurred.");
        setData([]);
      }
    };

    const processCostData = (body) => {
      const groupedData = body.map((entry) => {
        const month =
          granularity === "MONTHLY"
            ? new Date(entry.TimePeriod.Start).toLocaleString("default", {
              month: "long",
              year: "numeric",
            })
            : `${entry.TimePeriod.Start} to ${entry.TimePeriod.End}`;

        return entry.Groups.map((group) => {
          const key = group.Keys[0];
          const metrics = group.Metrics;
          const totalCost =
            parseFloat(metrics.BlendedCost.Amount) +
            parseFloat(metrics.NetAmortizedCost.Amount) +
            parseFloat(metrics.NetUnblendedCost.Amount);

          return { month, key, totalCost };
        });
      });

      const flattenedData = groupedData.flat();

      const chartData = {};
      flattenedData.forEach(({ month, key, totalCost }) => {
        if (!chartData[month]) {
          chartData[month] = { month };
        }
        chartData[month][key] = totalCost;
      });

      return Object.values(chartData);
    };

    fetchData();
  }, [selectedFilters, fromDate, toDate, granularity, dimension]);


  const formatNumber = (value) => {
    if (value >= 1000) {
      return `$${(value / 1000).toFixed(0)}k`;
    }
    return `$${value}`;
  };

  const appliedFiltersCount = Object.values(selectedFilters).reduce(
    (count, filterArray) => count + filterArray.length,
    0
  );

  const getSeries = () => {
    const allKeys = new Set();
    data.forEach((entry) => {
      Object.keys(entry).forEach((key) => {
        if (key !== "month") allKeys.add(key);
      });
    });

    return Array.from(allKeys).map((key, index) => ({
      name: key,
      color: `hsl(${(index * 30) % 360}, 70%, 50%)`,
    }));
  };

  const series = getSeries();

  const renderCustomLegend = () => (
    <div className="custom-legend">
      {series.map((item) => (
        <div key={item.name} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: item.color }}
          ></span>
          <span className="legend-name">{item.name}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="main-container">
      <div style={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
        <div style={{
          width: 'calc(100% - 340px)'
          , gap: '20px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div className="glassmorph-container-body glassmorph"><p className="font-size-24and28 font-weight-semibold">Cost Explorer</p>
            <div style={{
              display: "flex",
              width: "100%",
              gap: "20px"
            }}>
              <Select
                label="Dimension"
                value={dimension}
                onChange={setDimension}
                data={dimensionOptions}
                labelProps={{ style: { color: "white" } }}
                style={{ width: "100%" }}
              />
              <Select
                label="Granularity"
                value={granularity}
                onChange={setGranularity}
                data={granularityOptions}
                labelProps={{ style: { color: "white" } }}
                style={{ width: "100%" }}
              />
              <DatePickerInput
                label="From Date"
                value={fromDate}
                valueFormat="YYYY MMM DD"
                onChange={setFromDate}
                labelProps={{ style: { color: "white" } }}
                style={{ width: "100%" }}
              />
              <DatePickerInput
                label="To Date"
                value={toDate}
                valueFormat="YYYY MMM DD"
                onChange={setToDate}
                labelProps={{ style: { color: "white" } }}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          {error && (
            <Alert
              variant="light"
              color="red"
              title="Error"
              icon={<IconInfoCircle />}
              className="fill-highlight-red-background"
            >
              {error}
            </Alert>
          )}

          {!error && data.length === 0 ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
                borderRadius: '8px',
              }}
            >
              <Loader size="lg" variant="oval" color="white" />
            </div>
          ) : (
            data.length > 0 && (
              <div
                className="glassmorph"
                style={{
                  padding: '20px',
                  borderRadius: '8px',
                  gap: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <div style={{
                  gap: '20px', display: 'flex', flexDirection: 'column', backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px'
                }}>
                  <BarChart
                    h={300}
                    data={data}
                    dataKey="month"
                    type="stacked"
                    xAxisProps={{ padding: { left: 30, right: 30 } }}
                    valueFormatter={formatNumber}
                    series={series}
                    tooltipProps={{ wrapperStyle: { zIndex: 2 } }}
                  />
                  {renderCustomLegend()}
                </div>
                <div style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}>
                  <span className="font-size-20 font-weight-semibold">Cost Usage Breakdown</span>
                  <PaginatedTable data={data} selectedDimension={dimension} />
                </div>
              </div>
            )
          )}

        </div>
        <div style={{ width: '340px', padding: '40px 28px' }} className="glassmorph-container-body glassmorph">
          <p className="font-size-24and28 font-weight-semibold">Filters</p>
          <div style={{
            marginTop: '10px', display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <span>Applied filters ({appliedFiltersCount})</span>
            <Button
              variant="outline"
              size="xs"
              style={{ marginLeft: '10px' }}
              onClick={handleClearAll}
            >
              Clear all
            </Button>
          </div>
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <div
              id="filter-service"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Service"
                options={filterData.filters.find((f) => f.id === 'filter-service').options}
                initialSelectedOptions={selectedFilters['filter-service']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-service'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-service': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-service').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-service').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-linked-account"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Linked Account"
                options={filterData.filters.find((f) => f.id === 'filter-linked-account').options}
                initialSelectedOptions={selectedFilters['filter-linked-account']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-linked-account'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-linked-account': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-linked-account').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-linked-account').style.zIndex = 0;
                }}
              />
            </div>
            <div
              id="filter-region"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Region"
                options={filterData.filters.find((f) => f.id === 'filter-region').options}
                initialSelectedOptions={selectedFilters['filter-region']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-region'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-region': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-region').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-region').style.zIndex = 0;
                }}
              />
            </div>

            {/* Instance Type */}
            <div
              id="filter-instance-type"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Instance Type"
                options={filterData.filters.find((f) => f.id === 'filter-instance-type').options}
                initialSelectedOptions={selectedFilters['filter-instance-type']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-instance-type'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-instance-type': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-instance-type').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-instance-type').style.zIndex = 0;
                }}
              />
            </div>

            {/* Usage Type */}
            <div
              id="filter-usage-type"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Usage Type"
                options={filterData.filters.find((f) => f.id === 'filter-usage-type').options}
                initialSelectedOptions={selectedFilters['filter-usage-type']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-usage-type'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-usage-type': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-usage-type').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-usage-type').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-usage-type-group"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Usage Type Group"
                options={filterData.filters.find((f) => f.id === 'filter-usage-type-group').options}
                initialSelectedOptions={selectedFilters['filter-usage-type-group']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-usage-type-group'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-usage-type-group': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-usage-type-group').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-usage-type-group').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-resource"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Resource"
                options={filterData.filters.find((f) => f.id === 'filter-resource').options}
                initialSelectedOptions={selectedFilters['filter-resource']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-resource'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-resource': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-resource').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-resource').style.zIndex = 0;
                }}
              />
            </div>

            {/* Cost Category */}
            <div
              id="filter-cost-category"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Cost Category"
                options={filterData.filters.find((f) => f.id === 'filter-cost-category').options}
                initialSelectedOptions={selectedFilters['filter-cost-category']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-cost-category'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-cost-category': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-cost-category').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-cost-category').style.zIndex = 0;
                }}
              />
            </div>

            {/* Tag */}
            <div
              id="filter-tag"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Tag"
                options={filterData.filters.find((f) => f.id === 'filter-tag').options}
                initialSelectedOptions={selectedFilters['filter-tag']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-tag'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-tag': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-tag').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-tag').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-charge-type"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Charge Type"
                options={filterData.filters.find((f) => f.id === 'filter-charge-type').options}
                initialSelectedOptions={selectedFilters['filter-charge-type']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-charge-type'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-charge-type': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-charge-type').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-charge-type').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-availability-zone"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Availability Zone"
                options={filterData.filters.find((f) => f.id === 'filter-availability-zone').options}
                initialSelectedOptions={selectedFilters['filter-availability-zone']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-availability-zone'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-availability-zone': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-availability-zone').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-availability-zone').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-platform"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Platform"
                options={filterData.filters.find((f) => f.id === 'filter-platform').options}
                initialSelectedOptions={selectedFilters['filter-platform']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-platform'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-platform': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-platform').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-platform').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-purchase-option"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Purchase Option"
                options={filterData.filters.find((f) => f.id === 'filter-purchase-option').options}
                initialSelectedOptions={selectedFilters['filter-purchase-option']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-purchase-option'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-purchase-option': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-purchase-option').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-purchase-option').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-tenancy"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Tenancy"
                options={filterData.filters.find((f) => f.id === 'filter-tenancy').options}
                initialSelectedOptions={selectedFilters['filter-tenancy']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-tenancy'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-tenancy': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-tenancy').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-tenancy').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-database-engine"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Database Engine"
                options={filterData.filters.find((f) => f.id === 'filter-database-engine').options}
                initialSelectedOptions={selectedFilters['filter-database-engine']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-database-engine'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-database-engine': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-database-engine').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-database-engine').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-legal-entity"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Legal Entity"
                options={filterData.filters.find((f) => f.id === 'filter-legal-entity').options}
                initialSelectedOptions={selectedFilters['filter-legal-entity']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-legal-entity'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-legal-entity': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-legal-entity').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-legal-entity').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-billing-entity"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="Billing Entity"
                options={filterData.filters.find((f) => f.id === 'filter-billing-entity').options}
                initialSelectedOptions={selectedFilters['filter-billing-entity']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-billing-entity'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-billing-entity': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-billing-entity').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-billing-entity').style.zIndex = 0;
                }}
              />
            </div>

            <div
              id="filter-api-operation"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '12px'
              }}
              className="glassmorph-lv1"
            >
              <FiltersDropdown
                heading="API Operation"
                options={filterData.filters.find((f) => f.id === 'filter-api-operation').options}
                initialSelectedOptions={selectedFilters['filter-api-operation']}
                clearAll={clearAll}
                onOptionsChange={(selected, unselected) => {
                  setSelectedFilters((prev) => {
                    const current = prev['filter-api-operation'] ?? [];

                    
                    const sameLength = current.length === selected.length;
                    const sameItems =
                      sameLength &&
                      current.every((val) => selected.includes(val)) &&
                      selected.every((val) => current.includes(val));

                    if (sameItems) {
                      return prev;
                    }

                    
                    return {
                      ...prev,
                      'filter-api-operation': selected,
                    };
                  });
                }}
                onDropdownOpen={() => {
                  document.getElementById('filter-api-operation').style.zIndex = 1;
                }}
                onDropdownClose={() => {
                  document.getElementById('filter-api-operation').style.zIndex = 0;
                }}
              />
            </div>

            <p className="font-size-24and28 font-weight-semibold">Advanced Options</p>
            <div style={{ marginTop: '10px' }}>
              <Checkbox label="Unblended Costs" />
              <Checkbox label="Additional Data Settings" />
              <Checkbox label="Show Forecasted Values" />
              <Checkbox label="Show Only Untagged Resources" />
              <Checkbox label="Show Only Uncategorized Resources" />
              <Checkbox label="Show Usage as Normalized Units" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostDataGraph;
