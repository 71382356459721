import './App.css';
import './Home.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import { ToastContainer } from 'react-toastify';
import Appheader from './Appheader';
import Lambda from './Lambda';
import Organization from './Organization';
import Chart from './components/Chart';
import CostExplorer from './CostExplorer';
import TestGraph from './TestGraph';
import Demo from './Demo.js';
import RandomPage from './RandomPage';
import { Amplify } from 'aws-amplify';
import {
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes,
  signOut
} from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { STSClient, AssumeRoleWithSAMLCommand } from "@aws-sdk/client-sts";

const AWS_COGNITO_DOMAIN = "us-east-1joaxrsjfl.auth.us-east-1.amazoncognito.com";
const CLIENT_ID = "5gqdfhi20oano1oim3cq06ladu";
const REDIRECT_URI = "https://main.d3hsgjzfhck6r6.amplifyapp.com/";
const LOGOUT_URI = "https://main.d3hsgjzfhck6r6.amplifyapp.com/login"; // Redirect to login after logout
const AWS_REGION = "us-east-1";
const PRINCIPAL_ARN = "arn:aws:iam::ACCOUNT_ID:saml-provider/Pfizer"; // Replace with your SAML Provider ARN

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: "us-east-1_JoAXRsjfL",
      userPoolClientId: CLIENT_ID,
      region: "us-east-1",
      loginWith: {
        oauth: {
          domain: AWS_COGNITO_DOMAIN,
          scopes: ["openid", "email", "profile"],
          redirectSignIn: REDIRECT_URI,
          redirectSignOut: LOGOUT_URI,
          responseType: "code"
        }
      }
    }
  }
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userEmail, setUserEmail] = useState("");
  const [iamRole, setIamRole] = useState("");
  const [awsCredentials, setAwsCredentials] = useState(null);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        // Check for Authorization Code in URL (OAuth Login)
        const urlParams = new URLSearchParams(window.location.search);
        const authCode = urlParams.get("code");

        if (authCode) {
          // Exchange Authorization Code for Tokens
          const tokenResponse = await fetch(`https://${AWS_COGNITO_DOMAIN}/oauth2/token`, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              grant_type: "authorization_code",
              client_id: CLIENT_ID,
              redirect_uri: REDIRECT_URI,
              code: authCode
            })
          });

          const data = await tokenResponse.json();
          sessionStorage.setItem("id_token", data.id_token);
          sessionStorage.setItem("access_token", data.access_token);
          window.history.replaceState(null, "", "/"); // Remove auth code from URL
        }

        // Check if user session is valid
        const session = await fetchAuthSession();
        if (!session.tokens?.idToken) {
          setIsAuthenticated(false);
          return;
        }

        // Fetch user details
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes();

        console.log("Authenticated User:", user);
        console.log("User Email:", attributes.email);

        setUserEmail(attributes.email);
        setIsAuthenticated(true);

        // Extract SAML & IAM Role
        const { samlAssertion, iamRole } = await fetchUserRole();
        if (iamRole) {
          setIamRole(iamRole);
          await assumeIAMRole(samlAssertion, iamRole);
        }
      } catch (error) {
        console.error("Authentication Error:", error);
        setIsAuthenticated(false);
      }
    };

    authenticateUser();
  }, []);

  const fetchUserRole = async () => {
    const idToken = sessionStorage.getItem("id_token");
    if (!idToken) {
      console.error("No ID Token found!");
      return {};
    }

    const decoded = jwtDecode(idToken);
    console.log("Decoded Token:", decoded);

    const samlAssertion = decoded["cognito:groups"] || decoded["custom:samlAssertion"];
    const iamRole = decoded["custom:iamRole"] || decoded["https://aws.amazon.com/SAML/Attributes/Role"];

    console.log("SAML Assertion:", samlAssertion);
    console.log("IAM Role:", iamRole);

    return { samlAssertion, iamRole };
  };

  const assumeIAMRole = async (samlAssertion, iamRole) => {
    if (!samlAssertion || !iamRole) {
      console.error("No SAML assertion or IAM Role available.");
      return;
    }

    try {
      const stsClient = new STSClient({ region: AWS_REGION });

      const command = new AssumeRoleWithSAMLCommand({
        RoleArn: iamRole,
        PrincipalArn: PRINCIPAL_ARN,
        SAMLAssertion: samlAssertion
      });

      const response = await stsClient.send(command);
      console.log("IAM Credentials:", response.Credentials);

      setAwsCredentials(response.Credentials);

      sessionStorage.setItem("aws_access_key_id", response.Credentials.AccessKeyId);
      sessionStorage.setItem("aws_secret_access_key", response.Credentials.SecretAccessKey);
      sessionStorage.setItem("aws_session_token", response.Credentials.SessionToken);
    } catch (error) {
      console.error("Error assuming IAM role:", error);
    }
  };

  const IDENTITY_PROVIDER = "Pfizer";

  const handleSSOLogin = () => {
    window.location.href = `https://${AWS_COGNITO_DOMAIN}/oauth2/authorize?client_id=${CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${REDIRECT_URI}&identity_provider=${IDENTITY_PROVIDER}`;
  };
  

  // Logout Function
  const handleLogout = async () => {
    try {
      await signOut();
      sessionStorage.clear();
      window.location.href = `https://${AWS_COGNITO_DOMAIN}/logout?client_id=${CLIENT_ID}&logout_uri=${LOGOUT_URI}`;
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <ToastContainer theme="colored" position="top-center" />
      <BrowserRouter>
        <Appheader />
        {isAuthenticated ? (
          <>
            <h3 style={{marginTop: '99px'}}>Welcome, {userEmail}!</h3>
            <h4 style={{marginTop: '8px'}}>IAM Role: {iamRole || "No Role Assigned"}</h4>
            <h4 style={{marginTop: '8px'}}>AWS Credentials: {awsCredentials ? "Available" : "Not Retrieved"}</h4>
            <button style={{marginTop: '8px'}} onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <button style={{marginTop: '99px'}} onClick={handleSSOLogin}>Login with SSO</button>
        )}
        <Routes>
          <Route path="/home" element={isAuthenticated ? <Home /> : <Navigate to="/login" replace />} />
          <Route path="/lambda" element={isAuthenticated ? <Lambda /> : <Navigate to="/login" replace />} />
          <Route path="/chart" element={isAuthenticated ? <Chart /> : <Navigate to="/login" replace />} />
          <Route path="/organization" element={isAuthenticated ? <Organization /> : <Navigate to="/login" replace />} />
          <Route path="/cost-explorer" element={isAuthenticated ? <CostExplorer /> : <Navigate to="/login" replace />} />
          <Route path="/test-graph" element={isAuthenticated ? <TestGraph /> : <Navigate to="/login" replace />} />
          <Route path="/demo" element={isAuthenticated ? <Demo /> : <Navigate to="/login" replace />} />
          <Route path="/randompage" element={isAuthenticated ? <RandomPage /> : <Navigate to="/login" replace />} />
          <Route path='/randompagedos' element={<RandomPage />} />
          <Route path='/ec2page' element={<Demo />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;