// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
//   Paper, CircularProgress, Typography, TablePagination, Alert, Checkbox, Button
// } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import API_URLS from './APIConfig';
// import './Lambda.css';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   backgroundColor: theme.palette.common.white,
//   color: theme.palette.common.black,
//   fontSize: '0.8rem',
// }));

// const HeaderTableCell = styled(StyledTableCell)({
//   backgroundColor: '#ADD8E6',
//   fontWeight: 'bold',
//   color: 'white',
// });

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

// const Lambda = () => {
//   const [lambdaFunctions, setLambdaFunctions] = useState([]);
//   const [filteredLambdas, setFilteredLambdas] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [selected, setSelected] = useState([]);

//   useEffect(() => {
//     const fetchLambdaFunctions = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const response = await axios.get(API_URLS.LAMBDA_FUNCTIONS, {
//           headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//           },
//         });
//         const parsedData = response.data.body ? JSON.parse(response.data.body) : response.data;
//         if (Array.isArray(parsedData)) {
//           setLambdaFunctions(parsedData);
//           setFilteredLambdas(parsedData);
//         } else {
//           setError('Unexpected data format.');
//         }
//       } catch (err) {
//         setError('Error fetching Lambda functions. Please check the console for more details.');
//         console.error('Error fetching Lambda functions:', err);
//       }
//       setLoading(false);
//     };
//     fetchLambdaFunctions();
//   }, []);

//   const handleChangePage = (event, newPage) => setPage(newPage);
//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleSelectAll = (event) => {
//     if (event.target.checked) {
//       const newSelected = filteredLambdas.map((lambda) => lambda.arn);
//       setSelected(newSelected);
//     } else {
//       setSelected([]);
//     }
//   };

//   const handleSelectRow = (arn) => {
//     const selectedIndex = selected.indexOf(arn);
//     let newSelected = [];

//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, arn);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
//     }

//     setSelected(newSelected);
//   };

//   const isSelected = (arn) => selected.indexOf(arn) !== -1;

//   if (loading) return <CircularProgress />;
//   if (error) return <Alert severity="error">{error}</Alert>;
//   if (!loading && lambdaFunctions.length === 0) return <Typography variant="h6">No Lambda functions found.</Typography>;

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
//       <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
//         Lambda Functions
//       </Typography>
//       <div style={{ width: '88%', display: 'flex', justifyContent: 'flex-end', paddingRight: '110px', marginBottom: '10px' }}>
//         <Button variant="contained" style={{ backgroundColor: 'grey', color: 'white' }}>
//           Scan
//         </Button>
//       </div>
//       <TableContainer component={Paper} style={{
//         boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
//         overflow: 'hidden',
//         position: 'relative',
//         backdropFilter: 'blur(6px)',
//         width: '92%'
//       }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <HeaderTableCell padding="checkbox">
//                 <Checkbox
//                   indeterminate={selected.length > 0 && selected.length < filteredLambdas.length}
//                   checked={filteredLambdas.length > 0 && selected.length === filteredLambdas.length}
//                   onChange={handleSelectAll}
//                 />
//               </HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Name</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Region</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Environment</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Memory</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Validated</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Best Cost</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Best Time</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Final Memory</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Is Optimized</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Category</HeaderTableCell>
//               <HeaderTableCell style={{ textAlign: 'center' }}>Graph</HeaderTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {filteredLambdas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lambda) => {
//               const isItemSelected = isSelected(lambda.name);
//               return (
//                 <StyledTableRow key={lambda.name} selected={isItemSelected}>
//                   <StyledTableCell padding="checkbox">
//                     <Checkbox
//                       checked={isItemSelected}
//                       onChange={() => handleSelectRow(lambda.name)}
//                     />
//                   </StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda.name}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda.region}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda.environment}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda.memory}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda['is_validated'] ? 'True' : 'False'}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda['best-cost']}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda['best-time']}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda['final-memory']}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda['is-optimized'] ? 'True' : 'False'}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>{lambda.category}</StyledTableCell>
//                   <StyledTableCell style={{ textAlign: 'center' }}>
//                     {lambda.graph ? (
//                       <img src={lambda.graph} alt="Graph" style={{ width: '100px', height: 'auto' }} />
//                     ) : (
//                       'N/A'
//                     )}
//                   </StyledTableCell>
//                 </StyledTableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <div style={{ width: '88%', display: 'flex', justifyContent: 'flex-end', paddingRight: '100px', marginTop: '10px' }}>
//         <Button variant="contained" style={{ backgroundColor: 'grey', color: 'white' }}>
//           Optimize
//         </Button>
//       </div>
//       <TablePagination
//         rowsPerPageOptions={[5, 10, 25]}
//         component="div"
//         count={filteredLambdas.length}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={handleChangePage}
//         onRowsPerPageChange={handleChangeRowsPerPage}
//       />
//     </div>
//   );
// };

// export default Lambda;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, CircularProgress, Typography, TablePagination, Alert, Checkbox, Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import API_URLS from './APIConfig';
import './Lambda.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  fontSize: '0.8rem',
}));

const HeaderTableCell = styled(StyledTableCell)({
  backgroundColor: '#ADD8E6',
  fontWeight: 'bold',
  color: 'white',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Lambda = () => {
  const [lambdaFunctions, setLambdaFunctions] = useState([]);
  const [filteredLambdas, setFilteredLambdas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const fetchLambdaFunctions = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(API_URLS.LAMBDA_FUNCTIONS, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        });

        // Parse the response body from the API
        const parsedData = response.data.body ? JSON.parse(response.data.body) : [];

        if (Array.isArray(parsedData)) {
          setLambdaFunctions(parsedData);
          setFilteredLambdas(parsedData);
        } else {
          setError('Unexpected data format.');
        }
      } catch (err) {
        setError('Error fetching Lambda functions. Please check the console for more details.');
        console.error('Error fetching Lambda functions:', err);
      }
      setLoading(false);
    };

    fetchLambdaFunctions();
  }, []);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = filteredLambdas.map((lambda) => lambda.Name);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const stats = [
    {
      averagePrice: 0.0000010387,
      averageDuration: 610.3333333333334,
      totalCost: 0.000010494099999999998,
      value: 128,
    },
    {
      averagePrice: 0.0000010131999999999999,
      averageDuration: 298,
      totalCost: 0.000010166,
      value: 256,
    },
    {
      averagePrice: 0.0000010064,
      averageDuration: 148,
      totalCost: 0.000010220399999999998,
      value: 512,
    },
    {
      averagePrice: 0.000001156,
      averageDuration: 84.83333333333333,
      totalCost: 0.000011668799999999997,
      value: 1024,
    },
    {
      averagePrice: 0.0000019583999999999997,
      averageDuration: 71.83333333333333,
      totalCost: 0.000019611199999999996,
      value: 2048,
    },
    {
      averagePrice: 0.00000291635,
      averageDuration: 72.16666666666667,
      totalCost: 0.00002880395,
      value: 3008,
    },
    {
      averagePrice: 0.0000039167999999999995,
      averageDuration: 71.5,
      totalCost: 0.00003884159999999999,
      value: 4096,
    },
    {
      averagePrice: 0.000004964,
      averageDuration: 72.83333333333333,
      totalCost: 0.000049436000000000006,
      value: 5120,
    },
    {
      averagePrice: 0.000005875199999999999,
      averageDuration: 71.16666666666667,
      totalCost: 0.0000604656,
      value: 6144,
    },
    {
      averagePrice: 0.000006663999999999999,
      averageDuration: 70,
      totalCost: 0.00006635440000000001,
      value: 7168,
    },
    {
      averagePrice: 0.000007833599999999999,
      averageDuration: 71.33333333333333,
      totalCost: 0.00007779199999999999,
      value: 8192,
    },
    {
      averagePrice: 0.0000089352,
      averageDuration: 72.33333333333333,
      totalCost: 0.00008898479999999999,
      value: 9216,
    },
    {
      averagePrice: 0.00000952,
      averageDuration: 70,
      totalCost: 0.00009560800000000001,
      value: 10240,
    },
  ];

  const handleSelectRow = (name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!loading && lambdaFunctions.length === 0) return <Typography variant="h6">No Lambda functions found.</Typography>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '99px' }}>
      <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
        Lambda Functions
      </Typography>
      <div style={{ width: '88%', display: 'flex', justifyContent: 'flex-end', paddingRight: '110px', marginBottom: '10px' }}>
        <Button variant="contained" style={{ backgroundColor: 'grey', color: 'white' }}>
          Scan
        </Button>
      </div>
      <TableContainer component={Paper} style={{
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        position: 'relative',
        backdropFilter: 'blur(6px)',
        width: '92%',
      }}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < filteredLambdas.length}
                  checked={filteredLambdas.length > 0 && selected.length === filteredLambdas.length}
                  onChange={handleSelectAll}
                />
              </HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Name</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Region</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Environment</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Memory</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Validated</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Best Cost</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Best Time</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Final Memory</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Is Optimized</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Category</HeaderTableCell>
              <HeaderTableCell style={{ textAlign: 'center' }}>Graph</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLambdas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((lambda) => {
              const isItemSelected = isSelected(lambda.Name);
              return (
                <StyledTableRow key={lambda.Name} selected={isItemSelected}>
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onChange={() => handleSelectRow(lambda.Name)}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.Name}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.Region}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.environment}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.Memory}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.Is_validated ? 'True' : 'False'}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.BestCost}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.BestTime}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.FinalMemory}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.Is_Optimized ? 'True' : 'False'}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>{lambda.Category}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: 'center' }}>
                    {lambda.Graph ? (
                      <img
                        src={lambda.Graph}
                        alt="Graph"
                        style={{ width: '100px', height: 'auto' }}
                      />
                    ) : (
                      <button
                        onClick={() => {
                          const encodedStats = encodeURIComponent(JSON.stringify(stats));
                          window.location.href = `/test-graph?data=${encodedStats}`;
                        }}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          color: '#007BFF',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                      >
                        Show Graph
                      </button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ width: '88%', display: 'flex', justifyContent: 'flex-end', paddingRight: '100px', marginTop: '10px' }}>
        <Button variant="contained" style={{ backgroundColor: 'grey', color: 'white' }}>
          Optimize
        </Button>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredLambdas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Lambda;