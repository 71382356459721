import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const processData = (stats) => {
  const labels = stats.map((item) => item.value); 
  const invocationTime = stats.map((item) => item.averageDuration); 
  const invocationCost = stats.map((item) => item.totalCost); 

  return {
    labels,
    datasets: [
      {
        label: 'Invocation Time (ms)',
        data: invocationTime,
        borderColor: 'red',
        yAxisID: 'y',
        tension: 0.4,
      },
      {
        label: 'Invocation Cost (USD)',
        data: invocationCost,
        borderColor: 'blue',
        yAxisID: 'y1',
        tension: 0.4,
      },
    ],
  };
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'AWS Lambda Power Tuning Results',
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Memory / Power (MB)',
      },
    },
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      title: {
        display: true,
        text: 'Invocation Time (ms)',
      },
      ticks: {
        callback: (value) => value.toLocaleString(),
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      title: {
        display: true,
        text: 'Invocation Cost (USD)',
      },
      ticks: {
        callback: (value) =>
          value.toLocaleString(undefined, {
            minimumFractionDigits: 8,
            maximumFractionDigits: 8,
          }),
      },
      grid: {
        drawOnChartArea: false, 
      },
    },
  },
};

export default function DualAxisChart() {
  const [searchParams] = useSearchParams();

  const rawData = searchParams.get('data');
  const stats = rawData ? JSON.parse(decodeURIComponent(rawData)) : [];

  const chartData = processData(stats);

  return (
    <div className='main-container'>
    <div
      className="background-white"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Line data={chartData} options={options} />
    </div>
    </div>
  );
}
