import React, { useState, useEffect } from 'react';
import { Checkbox, TextInput, ScrollArea } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSearch, IconX } from '@tabler/icons-react';

function FiltersDropdown({
  heading,
  options,
  initialSelectedOptions = [],  
  onDropdownOpen,
  clearAll,
  onDropdownClose,
  onOptionsChange,       
}) {
  const [selectedOptions, setSelectedOptions] = useState(() =>
    options.reduce((acc, option) => {
      acc[option] = initialSelectedOptions.includes(option);
      return acc;
    }, {})
  );

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownDirection, setDropdownDirection] = useState('down');

  useEffect(() => {
    if (onOptionsChange) {
      const selected = Object.keys(selectedOptions).filter(
        (option) => selectedOptions[option]
      );
      const unselected = Object.keys(selectedOptions).filter(
        (option) => !selectedOptions[option]
      );
      onOptionsChange(selected, unselected);
    }
  }, [selectedOptions, onOptionsChange]);

  useEffect(() => {
    if (clearAll) {
      setSelectedOptions(
        options.reduce((acc, option) => ({ ...acc, [option]: false }), {})
      );

      if (onOptionsChange) {
        onOptionsChange([], Object.keys(selectedOptions));
      }
    }
  }, [clearAll, options, onOptionsChange, selectedOptions]);

  useEffect(() => {
    setSelectedOptions((prev) =>
      options.reduce((acc, option) => {
        acc[option] = initialSelectedOptions.includes(option);
        return acc;
      }, {})
    );
  }, [initialSelectedOptions, options]);

  const toggleOption = (option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const removeOption = (option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: false,
    }));
  };

  const handleSelectAll = () => {
    setSelectedOptions(
      options.reduce((acc, option) => ({ ...acc, [option]: true }), {})
    );
  };

  const handleDeselectAll = () => {
    setSelectedOptions(
      options.reduce((acc, option) => ({ ...acc, [option]: false }), {})
    );
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedOptionKeys = Object.keys(selectedOptions).filter(
    (option) => selectedOptions[option]
  );

  const handleToggleDropdown = () => {
    if (isOpen) {
      console.log('Dropdown closed');
      setIsOpen(false);
      onDropdownClose?.();
    } else {
      console.log('Dropdown opened');
      setIsOpen(true);
      onDropdownOpen?.();
    }
  };

  const handleViewportCheck = () => {
    const dropdownElement = document.getElementById('dropdown-root');

    if (dropdownElement) {
      const rect = dropdownElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      setDropdownDirection(
        rect.bottom + 300 > viewportHeight ? 'up' : 'down'
      );

      console.log(
        `Dropdown position: ${rect.bottom + 300 > viewportHeight ? 'up' : 'down'
        }`
      );
    } else {
      console.log('Dropdown element not found');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownElements = document.querySelectorAll('.dropdown-root');

      let clickedInsideDropdown = false;

      dropdownElements.forEach((dropdownElement) => {
        if (dropdownElement.contains(event.target)) {
          clickedInsideDropdown = true;
        }
      });

      if (!clickedInsideDropdown) {
        console.log('Dropdown closed due to outside click');
        setIsOpen(null);
        onDropdownClose?.();
      } else {
        console.log('Click ignored: Inside dropdown');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onDropdownClose]);

  useEffect(() => {
    handleViewportCheck();
    window.addEventListener('resize', handleViewportCheck);
    return () => window.removeEventListener('resize', handleViewportCheck);
  }, []);

  return (
    <div className="dropdown-root" style={{ position: 'relative', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{heading}</span>
        <span
          style={{
            fontSize: '14px',
            color: 'blue',
            cursor: 'pointer',
          }}
          onClick={handleDeselectAll}
        >
          Clear
        </span>
      </div>

      <div
        onClick={handleToggleDropdown}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '8px',
          background: '#f9f9f9',
          borderRadius: '4px',
          border: '1px solid #ddd',
          cursor: 'pointer',
          borderRadius: '8px',
        }}
      >
        <span>Select {heading}</span>
        {isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
      </div>

      {selectedOptionKeys.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
            marginTop: '8px',
            alignItems: 'center',
          }}
        >
          {selectedOptionKeys.map((option) => (
            <div
              key={option}
              style={{
                display: 'flex',
                alignItems: 'center',
                background: '#f0f0f0',
                borderRadius: '4px',
                padding: '4px 8px',
              }}
            >
              <span style={{ marginRight: '4px' }}>{option}</span>
              <IconX
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => removeOption(option)}
              />
            </div>
          ))}
        </div>
      )}

      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: dropdownDirection === 'down' ? '100%' : 'auto',
            bottom: dropdownDirection === 'up' ? '100%' : 'auto',
            backgroundColor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            width: '100%',
            marginTop: dropdownDirection === 'down' ? '8px' : '0',
            marginBottom: dropdownDirection === 'up' ? '8px' : '0',
            overflow: 'hidden',
          }}
        >
          <TextInput
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={<IconSearch size={16} />}
            style={{ margin: '8px' }}
          />
          <div
            style={{
              padding: '12px',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#555',
              borderBottom: '1px solid #eee',
            }}
          >
            {filteredOptions.length} options available
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px',
            }}
          >
            <div
              onClick={handleSelectAll}
              style={{
                cursor: 'pointer',
                color: '#007BFF',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#f0f8ff',
                textAlign: 'center',
              }}
            >
              Select All
            </div>
            <div
              onClick={handleDeselectAll}
              style={{
                cursor: 'pointer',
                color: '#007BFF',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#f0f8ff',
                textAlign: 'center',
              }}
            >
              Deselect All
            </div>
          </div>

          <ScrollArea
            scrollbarSize={4}
            styles={{
              root: {
                maxHeight: '200px',
                overflow: 'auto',
              },
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <Checkbox
                  key={option}
                  label={option}
                  checked={selectedOptions[option]}
                  onChange={() => toggleOption(option)}
                  style={{ padding: '8px 16px' }}
                />
              ))
            ) : (
              <div style={{ padding: '16px', textAlign: 'center', color: 'gray' }}>
                No options found
              </div>
            )}
          </ScrollArea>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px',
            }}
          >
            <div
              onClick={() => setIsOpen(false)}
              style={{
                cursor: 'pointer',
                color: '#007BFF',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#f0f8ff',
                textAlign: 'center',
              }}
            >
              Done
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FiltersDropdown;
