// src/authUtils.js
import { signOut } from 'aws-amplify/auth';

/**
 * Logs out the user and redirects to the home or login page.
 */
export async function logout() {
  try {
    await signOut();
    console.log('User successfully logged out.');
    window.location.href = '/login'; // Redirect after logout
  } catch (error) {
    console.error('Error during logout:', error);
  }
}
