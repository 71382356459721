import React, { useState, useEffect } from 'react';
import { IconChevronUp, IconChevronDown, IconX, IconSearch } from '@tabler/icons-react';
import { Checkbox, TextInput, ScrollArea } from '@mantine/core';

/**
 * A dropdown component that stores its selected items in the URL.
 *
 * @param {string} heading - Key used for labeling the dropdown and for the URL param (e.g. "iid", "type")
 * @param {string[]} allOptions - All available options for selection
 * @param {function} onUrlChange - Callback to invoke whenever the URL is updated
 */
function UrlFiltersDropdown({ heading, allOptions = [], onUrlChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  // ----- URL Sync: Read from URL on component mount -----
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rawParamValue = params.get(heading);

    if (rawParamValue) {
      try {
        const parsed = JSON.parse(rawParamValue);
        // Build an object { [option]: true } for each item in parsed
        const initialSelected = {};
        parsed.forEach((val) => {
          initialSelected[val] = true;
        });
        setSelectedOptions(initialSelected);
      } catch (error) {
        console.error('Failed to parse URL param for', heading, error);
      }
    }
  }, [heading]);

  // ----- Derived data -----
  // Convert selectedOptions object into a simple array of selected keys
  const selectedOptionKeys = Object.keys(selectedOptions).filter(
    (key) => selectedOptions[key]
  );

  // Filter the options for display based on search term
  const filteredOptions = allOptions.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // ----- UI Handlers -----
  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleOption = (option) => {
    const newSelected = { ...selectedOptions };
    if (newSelected[option]) {
      delete newSelected[option];
    } else {
      newSelected[option] = true;
    }
    setSelectedOptions(newSelected);
    updateUrlParams(newSelected);
  };

  const removeOption = (option) => {
    const newSelected = { ...selectedOptions };
    delete newSelected[option];
    setSelectedOptions(newSelected);
    updateUrlParams(newSelected);
  };

  const handleSelectAll = () => {
    // Select all from the *current filtered list*
    const newSelected = { ...selectedOptions };
    filteredOptions.forEach((option) => {
      newSelected[option] = true;
    });
    setSelectedOptions(newSelected);
    updateUrlParams(newSelected);
  };

  const handleDeselectAll = () => {
    // Deselect everything (global)
    setSelectedOptions({});
    updateUrlParams({});
  };

  // ----- URL Update Logic -----
  const updateUrlParams = (optionMap) => {
    // Convert optionMap to an array of strings
    const keys = Object.keys(optionMap).filter((k) => optionMap[k]);

    const params = new URLSearchParams(window.location.search);

    if (keys.length > 0) {
      // Store the array as a JSON string in the URL param
      params.set(heading, JSON.stringify(keys));
    } else {
      // If no items remain, remove this param
      params.delete(heading);
    }

    // Push updated params to the URL without losing existing
    window.history.replaceState(null, '', `?${params.toString()}`);

    // Notify that the URL changed (if a callback was provided)
    if (typeof onUrlChange === 'function') {
      onUrlChange();
    }
  };

  return (
    <div className="dropdown-root" style={{ position: 'relative', width: '100%' }}>
      {/* Heading and Clear Action */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '8px',
        }}
      >
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{heading}</span>
        <span
          style={{ fontSize: '14px', color: 'blue', cursor: 'pointer' }}
          onClick={handleDeselectAll}
        >
          Clear
        </span>
      </div>

      {/* Dropdown toggle area */}
      <div
        onClick={handleToggleDropdown}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '8px',
          background: '#f9f9f9',
          border: '1px solid #ddd',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
      >
        <span>Select {heading}</span>
        {isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
      </div>

      {/* Selected items list */}
      {selectedOptionKeys.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
            marginTop: '8px',
            alignItems: 'center',
          }}
        >
          {selectedOptionKeys.map((option) => (
            <div
              key={option}
              style={{
                display: 'flex',
                alignItems: 'center',
                background: '#f0f0f0',
                borderRadius: '4px',
                padding: '4px 8px',
              }}
            >
              <span style={{ marginRight: '4px' }}>{option}</span>
              <IconX
                size={16}
                style={{ cursor: 'pointer' }}
                onClick={() => removeOption(option)}
              />
            </div>
          ))}
        </div>
      )}

      {/* Dropdown content */}
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            width: '100%',
            marginTop: '8px',
            overflow: 'hidden',
            zIndex: 999,
          }}
        >
          {/* Search box */}
          <TextInput
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            icon={<IconSearch size={16} />}
            style={{ margin: '8px' }}
          />

          {/* Info + Select/Deselect all */}
          <div
            style={{
              padding: '12px',
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#555',
              borderBottom: '1px solid #eee',
            }}
          >
            {filteredOptions.length} options available
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px',
            }}
          >
            <div
              onClick={handleSelectAll}
              style={{
                cursor: 'pointer',
                color: '#007BFF',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#f0f8ff',
                textAlign: 'center',
              }}
            >
              Select All
            </div>
            <div
              onClick={handleDeselectAll}
              style={{
                cursor: 'pointer',
                color: '#007BFF',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#f0f8ff',
                textAlign: 'center',
              }}
            >
              Deselect All
            </div>
          </div>

          {/* Scrollable checkbox list */}
          <ScrollArea
            scrollbarSize={4}
            styles={{
              root: {
                maxHeight: '200px',
                overflow: 'auto',
              },
            }}
          >
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <Checkbox
                  key={option}
                  label={option}
                  checked={!!selectedOptions[option]}
                  onChange={() => toggleOption(option)}
                  style={{ padding: '8px 16px' }}
                />
              ))
            ) : (
              <div style={{ padding: '16px', textAlign: 'center', color: 'gray' }}>
                No options found
              </div>
            )}
          </ScrollArea>

          {/* Close the dropdown */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px',
            }}
          >
            <div
              onClick={() => setIsOpen(false)}
              style={{
                cursor: 'pointer',
                color: '#007BFF',
                fontSize: '14px',
                padding: '8px',
                borderRadius: '4px',
                backgroundColor: '#f0f8ff',
                textAlign: 'center',
                width: '100%',
              }}
            >
              Done
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UrlFiltersDropdown;
