import React, { useState, useEffect, useMemo } from 'react';
import { Table, TextInput, Button, Checkbox } from '@mantine/core';
import { IconSearch, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import UrlFiltersDropdown from './UrlFiltersDropdown'; // <-- Your new dropdown component

/**
 * Renders a table and uses three instances of UrlFiltersDropdown
 * to filter the first three columns of the table based on values
 * stored in the URL query parameters.
 *
 * @param {Array} data - The table data (array of objects).
 * @param {Array} actionButtons - Optional array of action button definitions. (Each { label, onClick })
 * @param {number} maxColumns - How many columns to show before "More" is used.
 */
const NonPaginatedTable = ({ data, actionButtons = [], maxColumns = 5 }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedRows, setExpandedRows] = useState({}); // Tracks which rows are expanded
  const [selectedRows, setSelectedRows] = useState([]); // Stores indices of selected rows in filteredData

  // We add this state to force a re-render whenever the URL changes
  const [urlVersion, setUrlVersion] = useState(0);

  // All columns from the data
  const allColumns = Object.keys(data[0] || {});
  // We only filter the first three columns:
  const filterColumns = allColumns.slice(0, 3);

  // Collect unique values for each of the first 3 columns
  const columnUniqueValues = useMemo(() => {
    const colVals = {};
    filterColumns.forEach((colKey) => {
      const uniqueSet = new Set();
      data.forEach((row) => {
        if (row[colKey] !== undefined && row[colKey] !== null) {
          uniqueSet.add(String(row[colKey]));
        }
      });
      colVals[colKey] = Array.from(uniqueSet);
    });
    return colVals;
  }, [data, filterColumns]);

  // Utility: parse a given URL param as JSON array (used by the dropdown)
  const getURLParamAsArray = (paramName) => {
    const params = new URLSearchParams(window.location.search);
    const rawValue = params.get(paramName);
    if (!rawValue) return [];
    try {
      // Expecting something like ["val1","val2"]
      return JSON.parse(rawValue);
    } catch (err) {
      return [];
    }
  };

  // For each of the first 3 columns, read the URL filters:
  // We rely on urlVersion so that we re-run this logic when the URL is updated
  const col1SelectedValues = getURLParamAsArray(filterColumns[0]);
  const col2SelectedValues = getURLParamAsArray(filterColumns[1]);
  const col3SelectedValues = getURLParamAsArray(filterColumns[2]);

  // Filter by search and by selected values in each of the first 3 columns
  const filteredData = useMemo(() => {
    return data.filter((row) => {
      // 1) Text search filter
      const matchesSearch = Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );
      if (!matchesSearch) return false;

      // 2) Column 1 filter (if anything is selected)
      if (
        col1SelectedValues.length > 0 &&
        !col1SelectedValues.includes(String(row[filterColumns[0]]))
      ) {
        return false;
      }
      // 3) Column 2 filter
      if (
        col2SelectedValues.length > 0 &&
        !col2SelectedValues.includes(String(row[filterColumns[1]]))
      ) {
        return false;
      }
      // 4) Column 3 filter
      if (
        col3SelectedValues.length > 0 &&
        !col3SelectedValues.includes(String(row[filterColumns[2]]))
      ) {
        return false;
      }

      return true;
    });
  }, [
    data,
    searchQuery,
    col1SelectedValues,
    col2SelectedValues,
    col3SelectedValues,
    filterColumns,
    // No need to explicitly include urlVersion here, because
    // colNSelectedValues are re-read each render and will change
    // after urlVersion changes, triggering a new filter pass.
  ]);

  // Expand/collapse rows
  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  // Format complex values (e.g., objects or arrays) for rendering
  const formatValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return (
          <ul style={{ margin: 0, paddingLeft: '20px' }}>
            {value.map((item, index) =>
              typeof item === 'object' ? (
                <li key={index}>
                  {Object.entries(item).map(([key, val]) => (
                    <span key={key}>
                      <strong>{key}:</strong> {val}{' '}
                    </span>
                  ))}
                </li>
              ) : (
                <li key={index}>{item}</li>
              )
            )}
          </ul>
        );
      }
      return (
        <div>
          {Object.entries(value).map(([key, val]) => (
            <span key={key}>
              <strong>{key}:</strong> {val}{' '}
            </span>
          ))}
        </div>
      );
    }
    return value; // Return primitive values (string/number) as-is
  };

  // --- Handling Selections ---
  const isRowSelected = (rowIndex) => selectedRows.includes(rowIndex);

  const toggleRowSelection = (rowIndex) => {
    setSelectedRows((prev) => {
      if (prev.includes(rowIndex)) {
        // Deselect
        return prev.filter((idx) => idx !== rowIndex);
      } else {
        // Select
        return [...prev, rowIndex];
      }
    });
  };

  // If every row in filteredData is selected, we consider "all" selected
  // If some but not all are selected, we consider it "indeterminate"
  const allSelected =
    selectedRows.length > 0 && selectedRows.length === filteredData.length;
  const someSelected =
    selectedRows.length > 0 && selectedRows.length < filteredData.length;

  const handleSelectAll = () => {
    if (allSelected) {
      // Deselect all
      setSelectedRows([]);
    } else {
      // Select all in current filtered data
      const allIndices = filteredData.map((_, idx) => idx);
      setSelectedRows(allIndices);
    }
  };

  // Bulk Action Button handlers:
  // We'll pass an array of the actual rows to the button's onClick
  const handleBulkButtonClick = (buttonOnClick) => {
    // gather the selected row objects from filteredData
    const selectedRowObjects = selectedRows.map((rowIndex) => filteredData[rowIndex]);
    // call the action button with all selected row objects
    buttonOnClick(selectedRowObjects);
  };

  return (
    <div>
      {/*  Render 3 UrlFiltersDropdowns for the first 3 columns  */}
      <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
        {filterColumns.map((colKey) => (
          <div key={colKey} style={{ flex: 1 }}>
            <UrlFiltersDropdown
              heading={colKey}
              allOptions={columnUniqueValues[colKey]}
              onUrlChange={() => {
                // Trigger a re-render by incrementing urlVersion
                setUrlVersion((prev) => prev + 1);
              }}
            />
          </div>
        ))}
      </div>

      <div className="glassmorph" style={{ padding: '12px', borderRadius: '12px' }}>
        {/* Search Field */}
        <TextInput
          placeholder="Search rows..."
          icon={<IconSearch />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: '20px' }}
        />

        {/* Show bulk action buttons if any rows are selected */}
        {actionButtons.length > 0 && selectedRows.length > 0 && (
          <div style={{ marginBottom: '16px', display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {actionButtons.map((button, index) => (
              <Button
                key={index}
                variant="filled"
                onClick={() => handleBulkButtonClick(button.onClick)}
              >
                {/* e.g. "Button 1 label 5/9 items" */}
                {button.label} {selectedRows.length}/{filteredData.length} items
              </Button>
            ))}
          </div>
        )}

        {/* Table */}
        <Table
          className="glassmorph-lv1"
          style={{
            color: 'black',
            borderRadius: '12px',
            overflow: 'hidden',
          }}
        >
          <Table.Thead>
            <Table.Tr>
              {/* Checkbox column header */}
              <Table.Th>
                <Checkbox
                  checked={allSelected}
                  indeterminate={someSelected}
                  onChange={handleSelectAll}
                />
              </Table.Th>

              {/* Display only the first maxColumns */}
              {allColumns.slice(0, maxColumns).map((key) => (
                <Table.Th key={key}>{key.toUpperCase()}</Table.Th>
              ))}
              {/* Indicate hidden columns */}
              {allColumns.length > maxColumns && <Table.Th>More</Table.Th>}
              {actionButtons.length > 0 && <Table.Th>Actions</Table.Th>}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {filteredData.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                {/* Main Row */}
                <Table.Tr>
                  {/* Per-row checkbox */}
                  <Table.Td>
                    <Checkbox
                      checked={isRowSelected(rowIndex)}
                      onChange={() => toggleRowSelection(rowIndex)}
                    />
                  </Table.Td>

                  {Object.entries(row)
                    .slice(0, maxColumns)
                    .map(([colKey, colValue], colIndex) => (
                      <Table.Td key={colIndex}>{formatValue(colValue)}</Table.Td>
                    ))}

                  {/* Expand/Collapse Button */}
                  {allColumns.length > maxColumns && (
                    <Table.Td>
                      <Button
                        variant="subtle"
                        size="xs"
                        onClick={() => toggleRowExpansion(rowIndex)}
                        style={{ padding: '0' }}
                      >
                        {expandedRows[rowIndex] ? (
                          <IconChevronUp size={16} />
                        ) : (
                          <IconChevronDown size={16} />
                        )}
                      </Button>
                    </Table.Td>
                  )}

                  {/* Action Buttons (row-level) */}
                  {actionButtons.length > 0 && (
                    <Table.Td>
                      {actionButtons.map((button, index) => (
                        <Button
                          key={index}
                          onClick={() => button.onClick([row])} // pass just this row
                          variant="outline"
                          size="xs"
                          style={{ marginRight: '5px' }}
                        >
                          {button.label}
                        </Button>
                      ))}
                    </Table.Td>
                  )}
                </Table.Tr>

                {/* Expanded Row */}
                {expandedRows[rowIndex] && allColumns.length > maxColumns && (
                  <Table.Tr>
                    <Table.Td
                      style={{ paddingLeft: '24px' }}
                      colSpan={
                        // The "More" column, the "Actions" column, the "Checkbox" column, + maxColumns
                        1 + // our new checkbox column
                        (allColumns.length > maxColumns ? 1 : 0) + // "More" column if present
                        (actionButtons.length > 0 ? 1 : 0) + // "Actions" column if present
                        maxColumns
                      }
                    >
                      {/* Instead of a sub-table, use a flex container */}
                      <div
                        className="glassmorph-lv3 glassmorph-stroke"
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '24px',
                          padding: '12px',
                          borderRadius: '12px',
                        }}
                      >
                        {allColumns.slice(maxColumns).map((colKey) => (
                          <div
                            key={colKey}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ fontWeight: 'bold', marginRight: 4 }}>
                              {colKey}:
                            </span>
                            <span>{formatValue(row[colKey])}</span>
                          </div>
                        ))}
                      </div>
                    </Table.Td>
                  </Table.Tr>
                )}
              </React.Fragment>
            ))}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

export default NonPaginatedTable;
