import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { MantineProvider } from '@mantine/core';

const customPurple = [
  '#fbeaff',
  '#eed1ff',
  '#d8a1fb',
  '#c26ef6',
  '#af42f2',
  '#a327f0',
  '#9d17f0',
  '#8909d6',
  '#7a04c0',
  '#6a00a9',
];

const theme = {
  colors: {
    customPurple,
  },
  primaryColor: 'customPurple',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <App />
    </MantineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();